import React from "react";
import BackArrow from "../../../assets/svg/LeftArrow.svg";

export const PNPModal = ({ handleClose }) => {
  const handleback = () => {
    handleClose();
  };

  return (
    <div>
      <div className="modal-overlayll">
        <div className="modalll">
          <div className="container_t">
            <img onClick={handleback} src={BackArrow} className="arrow" />
            <h1 className="tc-title">Privacy and Policy</h1>
          </div>
          <div className="modal-content">
            <div className="TCcontainer">
              <h1 className="tc-heading">Privacy Policy</h1>
              <ol className="tc-para" type="1">
                <li>
                  <b>Introduction.</b> We are committed to respecting your online privacy and recognize the need for
                  appropriate protection and management of any personally identifiable information you share with us.
                  This Privacy Policy (&quot;<b>Policy</b>&quot;) is an integral part of the Terms of Use and describes
                  how Rankguru Technology Solutions Private Limited (“<b>Company</b>”) collects, uses, discloses and
                  transfers your personal information through its website and applications under the brand name of
                  Infinity Learn, including{" "}
                  <a className="a-link" href="link">
                    https://inmobiusinfinitylearn.com
                  </a>
                  , its associated websites, mobile applications, SD cards, tablets or other storage or transmitting
                  device (collectively, the &quot;<b>Platform</b>&quot;). This Policy applies to those users who access
                  or use the Platform. By accessing, installing, copying or otherwise using the SD card, Platform or any
                  of its components, or by using the Services, you agree to the terms of this Privacy Policy. The
                  Company shall have the right to monitor the download and usage of the SD cards, Platform, products, or
                  Services, and the contents thereof by you to analyse your usage of the SD cards, Platform, products,
                  or Services, revise or update the content on the SD cards, Platform, products, or Services, or discuss
                  the same with the you to enable effective and efficient usage of the SD cards, Platform, products, or
                  Services. Terms not defined herein shall have meaning ascribed to them in the Terms of Use.
                </li>
                <br />
                <li>
                  You shouldn’t use the Services if you don’t agree with this Privacy Policy or any other agreement that
                  governs your access of the Platform and use of the Services. Your use of the SD cards, Platform, or
                  registrations with us through any modes or usage of any products including through SD cards, tablets
                  or other storage/transmitting device shall signify your acceptance of this Policy and your agreement
                  to be legally bound by the same. If you do not agree with the terms of this Policy, do not use the
                  Platform, including without limitation, the SD card, Website, our products, or avail any of our
                  Services.
                </li>
                <br />
                <li>
                  <b>Consent to receive Commercial Communications.</b> You hereby consent to be contacted by the Company
                  and its employees, officers, suppliers, agents, subcontractors, and representatives for commercial
                  communications with respect to advertising, promoting, marketing, or selling its products and
                  Services, including without limitation, receiving commercial communications and being contacted by way
                  of phone call, SMS, internet text messages, IVR, internet telephony or any other mode of
                  communication. I acknowledge and agree that this consent will override any registration for Do Not
                  Call (DNC) or National Do Not Call (NDNC) registry.
                </li>
                <br />
                <li>
                  <b>Types of Personal Information collected by us. &quot;Personal Information&quot; </b>
                  means any information relating to an identified or identifiable natural person including common
                  identifiers such as a name, an identification number, location data, an online identifier or one or
                  more factors specific to the physical, physiological, genetic, mental, economic, cultural or social
                  identity of that natural person and any other information that is so categorized by applicable laws.
                  We collect information about you and/or your usage to provide better services and offerings. The
                  Personal Information that we collect, and how we collect it, depends upon how you interact with us. We
                  may collect the following categories of Personal Information in the following ways:
                  <br />
                  <br />
                  <ul>
                    <li> name, email address, password, country, state, city, contact number and cookies;</li>
                    <li>
                      information about the services that you use and how you use them, including log information and
                      location information, when you are a user of the services through the Platform;
                    </li>
                    <li>
                      we may collect your Personal Information such as name, age, contact details, preferences, etc.
                      through surveys and forms, when you choose to participate in these surveys and forms;
                    </li>
                    <li>
                      we may also collect information relating to your caste and information about whether you are
                      eligible for any affirmative action programmes or policies, if you opt to provide such
                      information;
                    </li>
                    <li>
                      when you visit the Platform, we use cookies to automatically collect, store and use technical
                      information about your system and interaction with our Platform;
                    </li>
                    <li>
                      To the extent permitted by law, Company may record and monitor your communications with us to
                      ensure compliance with our legal and regulatory obligations and our internal policies. This may
                      include the recording of telephone conversations;
                    </li>
                    <li>
                      If you choose to sign in with your social media account to access the Platform, or otherwise
                      connect your social media account with the services of the Platform, you consent to our
                      collection, storage, and use of the information that you make available to us through the social
                      media interface, including Personal Information. Please see your social media provider&aposs
                      privacy policy and help centre for more information about how they share information when you
                      choose to connect your account.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <b>Automatically Collected Information.</b> In addition, the SD cards, Website, Platform or Services
                  may collect certain information automatically, including without limitation, the type of mobile device
                  you use, your mobile devices’ unique device ID, the IP address of your mobile device, your mobile
                  operating system, the type of mobile Internet browsers you use, and information about the way you use
                  the Website, Platform or Services. We also collect other relevant information in accordance with the
                  permissions that you provide.
                  <br />
                  <br />
                  At the time of accessing or registration on the Platform, we may require access to the SD cards,
                  camera and microphone access while you avail our products or Services in order to improve the quality
                  or experience of Services availed by you and revise or update the content of the SD cards from time to
                  time. All video, audio and telecalls between you and our service providers or representatives while
                  you avail Services are recorded for quality and training purposes. These recordings shall not be
                  shared with you at any time for any reason. By agreeing to this Privacy Policy, you (on behalf of
                  yourself and/or the user) consent to our collection, recording, processing, and use of the said
                  recordings and personal information for the above-mentioned purposes.
                </li>
                <br />
                <li>
                  <b>How we use your Personal Information.</b> We will use Personal Information in accordance with this
                  Policy and the applicable laws.
                  <br />
                  <br />
                  Our use of Personal Information depends on the purpose for which we contact you or you interact with
                  us. We may process Personal Information for the following purposes:
                  <br />
                  <br />
                  <ul>
                    <li>
                      Providing our services and products to you, including without limitation, sending commercial
                      communications and contacting you for commercial communications via phone call, SMS, IVR, internet
                      telephony etc., or sending you communications about our products and services, including without
                      limitation, phone call, SMS, IVR, internet telephony etc., newsletters, recommended courses and
                      other social media communication facilities;
                    </li>
                    <li>Providing you with customer support</li>
                    <li>
                      We use information collected from cookies and other technologies, to improve your user experience
                      and the overall quality of our services (for more information please refer to paragraph 4 below).
                    </li>
                    <li>Improving the Platform and its content to provide better features and services.</li>
                    <li>Conducting market research and surveys with the aim of improving our products and services.</li>
                    <li>
                      Sending you information about our products and services for marketing purposes and promotions
                      using various modes, including without limitation, sending commercial communications and
                      contacting you for commercial communications via phone call, SMS, IVR, internet telephony etc.;
                    </li>
                    <li>Preventing any illegal activities, suspected fraud, or violation of the Terms of Use;</li>
                    <li>To the extent required for identity verification and due diligence checks.</li>
                    <li>
                      Establishing, exercising or defending legal rights in connection with legal proceedings (including
                      any prospective legal proceedings).
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <b>Cookies and Other Tracking Technologies.</b> Some of the web pages of the Platform utilize cookies
                  and other tracking technologies. A cookie is a small text file that may be used, for example, to
                  collect information about activity on the Platform. Some cookies and other technologies may serve to
                  recall Personal Information previously indicated by a User. Most browsers allow you to control
                  cookies, including whether or not to accept them and how to remove them.
                  <br />
                  <br />
                  You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies
                  with your browser, but please note that if you choose to erase or block your cookies, you will need to
                  re-enter your original user ID and password to gain access to certain parts of the Platform.
                  <br />
                  <br />
                  Tracking technologies may record information such as internet domain and host names, IP addresses,
                  browser software and operating system types, clickstream patterns, and dates and times that the
                  Platform site is accessed. Our use of cookies and other tracking technologies allows us to improve our
                  Platform and the overall Platform experience. We may also analyse information that does not contain
                  Personal Information for trends and statistics.
                  <br />
                  <br />
                  For more information about our use of cookies please refer to our Cookie Policy set out below.
                </li>
                <br />
                <li>
                  <b>The grounds on which we rely on for collection and processing of your Personal Information.</b>{" "}
                  Your Personal Information is collected and processed by Company based on the following grounds
                  depending upon the nature of Personal Information and the purposes for which it is collected and
                  processed.
                </li>
                <br />
                <ul>
                  <li>
                    <b>Consent.</b> Company relies on your consent in order to process your Personal Information in
                    certain situations. Your consent is sought at the time of collection of your Personal Information
                    and such processing will only be performed where consent is obtained.
                  </li>
                  <br />
                  <li>
                    <b>Compliance with a legal obligation.</b> Your Personal Information may be processed by Company to
                    comply with a legal obligation. An example of this would be if Company is required to disclose your
                    Personal Information to respond to a lawful order or if Company is required to retain specific
                    records for a fixed period to comply with requirements under any applicable law.
                  </li>
                </ul>
                <br />
                <li>
                  <b>Information Sharing and Disclosure.</b> The Company restricts access to your Personal Information
                  to its employees and representatives who reasonably need to know such information to provide services
                  to you, and fulfil our obligations under the Terms of Use and applicable laws.
                  <br />
                  Company does not disclose, transfer or share your Personal Information with others except with:
                  <br />
                  <ul>
                    <li>
                      Our affiliates and group companies to the extent required for our internal business and
                      administrative purposes, general corporate operations and for provision of services;
                    </li>
                    <li>
                      Third parties including enforcement, regulatory and judicial authorities, if we determine that
                      disclosure of your Personal Information is required to a) respond to subpoenas, court orders, or
                      legal process, or to establish or exercise our legal rights or defend against legal claims; or b)
                      investigate, prevent, or take action regarding illegal activities, suspected fraud, violation of
                      Company’s Terms of Use or as otherwise required by law;
                    </li>
                    <li>
                      In the event of a merger, acquisition, financing, or sale of assets or any other situation
                      involving the assignment or transfer of some or all of Company’s business assets we may disclose
                      Personal Information to those involved in the negotiation of such assignment or transfer;
                    </li>
                    <li>
                      Third party service providers and marketing partners that Company engages to a) provide services
                      on the Platform on Company’s behalf; b) maintain the Platform and mailing lists; or c) communicate
                      with you on Company’s behalf about offers relating to its products or services. Company will
                      endeavour to ensure that these third party service providers are obligated to protect your
                      Personal Information and are also subject to appropriate confidentiality and non-disclosure
                      obligations.
                    </li>

                    <li>
                      Third party advertisers to display advertisements to you when you visit the Platform. Such third
                      parties may use information about you and your visits to the Platform and other websites to
                      display advertisements on this Platform.
                    </li>
                  </ul>
                  <br />
                  The Company does not provide any Personal Information to the advertiser when you interact with or view
                  a targeted advertisement. However, if you interact with or view an advertisement, the advertiser may
                  make certain assumptions and, in the process, learn certain Personal Information about you. For
                  instance, if you view and click an advertisement that is targeted towards women in the age group 18-
                  24 from a specific geographic area, the advertiser may assume that you meet the relevant criteria.{" "}
                  <br />
                  Company does not intend to transfer Personal Information without your consent to third parties who are
                  not bound to act on Company&aposs behalf unless such transfer is legally required. <br />
                  If your Personal Information is transferred outside India, we take the necessary steps to protect your
                  Personal Information in accordance with applicable data protection laws.
                  <br />
                </li>
                <br />
                <li>
                  <b>Third Party Content.</b> Please be aware that the Platform sometimes contains links to other sites
                  that are not governed by this Policy. You may be directed to third party sites for more information,
                  such as advertisers, blogs, content sponsorships, vendor services, social networks, etc.
                  <br />
                  Company makes no representations or warranties regarding how your information is stored or used on
                  third party servers. We recommend that you review the privacy policy of such third parties, including
                  the applicable privacy statements and policies of each third party site linked from the Platform to
                  determine their use of your Personal Information.
                </li>
                <br />
                <li>
                  <b>Children.</b> To use the Platform, you agree that you must be the minimum age (described in this
                  paragraph below) or older.
                  <br />
                  The minimum age for these purposes shall be eighteen (18), however if local laws require that you must
                  be older for Company to be able to lawfully provide the services over the Platform to you then that
                  older age shall apply as the applicable minimum age.
                  <br />
                  If you are under the age of 18 or the age of majority in your jurisdiction, you must use the Platform
                  under the supervision of your parent or legal guardian.
                </li>
                <br />
                <li>
                  <b>Retention of Personal Information.</b> Your Personal Information will be retained by Company for
                  such period of time as required for the purposes for which the Personal Information is processed, and
                  in accordance with legal, regulatory, contractual or statutory obligations or the Company’s policies,
                  as applicable.
                </li>
                <br />
                <li>
                  <b>Controlling your Personal Information.</b> You have the right to invoke your rights which are
                  available to data subjects as per applicable laws and regulations in relation to your Personal
                  Information which is processed by Company.
                  <br />
                  <br />
                  Company provides you the ability to keep your Personal Information accurate and up-to-date. If at any
                  time you would like to a) rectify, update or correct your Personal Information; b) obtain confirmation
                  on whether or not your Personal Information is processed by it; or c) exercise your right to restrict
                  the continuing disclosure of your Personal Information to any third party by Company in certain
                  circumstances, you are requested to contact us using the contact details mentions in paragraph 14
                  below
                </li>
                <br />
                <li>
                  <b>Confidentiality and Security.</b> The security and confidentiality of your Personal Information is
                  important to us and Company has invested significant resources to protect the safekeeping and
                  confidentiality of your Personal Information. When using third party service providers acting as
                  processors, we require that they adhere to the standards required under applicable laws. Regardless of
                  where your Personal Information is transferred or stored, we take all steps reasonably necessary to
                  ensure that Personal Information is kept secure. <br />
                  We comply with the requirements of the Information Technology Act, 2000 and Rules framed thereunder to
                  ensure the protection and preservation of your privacy. We have physical, electronic, and procedural
                  safeguards that comply with the appliable laws to protect your Personal Information.
                </li>
                <br />
                <li>
                  <b>Social Media.</b> Company operates channels, pages and accounts on some social media sites to
                  inform, assist and engage with its customers and users. Company monitors and records comments and
                  posts made on these channels about itself in order to improve its products and services.
                  <br />
                  <br />
                  Please note that you must not communicate the following information to Company through such social
                  media sites: <br />
                  <br />
                  sensitive personal data including (i) special categories of personal data meaning any information
                  revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade
                  union membership, and the processing of genetic data, biometric data for the purpose of uniquely
                  identifying a natural person, data concerning health or data concerning a natural person&aposs sex
                  life or sexual orientation and (ii) other sensitive personal data such as criminal convictions and
                  offences and national identification number. Company is not responsible for any information posted on
                  such sites other than the information posted by its employees on its behalf. Company is only
                  responsible for its own use of the Personal Information received through such sites.
                </li>
                <br />
                <li>
                  <b> Disclaimer. </b> Company does not store any account related information or any credit/debit card
                  details. Company shall not be liable for any loss or damage sustained by you as a result of any
                  disclosure (inadvertent or otherwise) of any information concerning your account, credit cards or
                  debit cards in the course of any online transactions or payments made for any products and services
                  offered through the Platform.
                </li>
                <br />
                <li>
                  <b>Amendments.</b> Company reserves the right to update, change or modify this Policy at any time. The
                  updated Privacy Policy shall come to effect from the date of publication of such update, change or
                  modification from time to time.
                </li>
                <br />
                <li>
                  <b>Contact us.</b>
                  If you have any questions regarding this Privacy Policy or the protection of your Personal
                  Information, or any complaints or grievances in relation to the processing of your Personal
                  Information please contact{" "}
                  <a className="a-link" href="link">
                    privacy@infinitylearn.com.
                  </a>
                </li>
              </ol>
              <p className="tc-para">
                <b>Cookie Policy</b>{" "}
              </p>
              <ol type="a" className="tc-para">
                <b>Introduction </b>
                This Cookie Policy explains how we use your Personal Information and provides detailed information about
                how and when we use cookies on the Platform. This Cookie Policy applies to any product or service
                offered by Company on the Platform.
                <br />
                <br /> By continuing to access or use the Platform, you agree to the use of cookies and similar
                technologies for the purposes we describe in this policy. If you prefer not to receive cookies, then you
                should stop using the Platform, or consult your browsing and third party cookie settings as described
                below.
                <br />
                <br /> By continuing to access or use the Platform, you agree that we can place cookies and web beacons
                on your device. If you prefer not to receive web beacons, then you should stop using our site, or visit
                your browsing settings.
                <br />
                <br />
                <ol type="a">
                  <li>
                    <b>Does Company use cookies?</b> <br />
                    <br />
                    Company uses cookies, tags and other technologies when you use the Platform. Cookies are used to
                    ensure everyone has their best possible experience. Cookies also help us keep your account safe.
                  </li>
                  <br />
                  <li>
                    <b>What is a cookie?</b>
                    <br />
                    <br />
                    Cookies are small pieces of text stored by a website you visit in your browser and subsequently sent
                    by your web browser in every request to the Platform. A cookie file is stored in your web browser
                    and allows the Platform or a third party to recognize you and make your next visit to the Platform
                    easier. Essentially, cookies are a your identification card for the Platform servers. Cookies allow
                    Company to serve you better and more efficiently, and to personalize your experience on the
                    Platform. Web beacons, tags and scripts may be used on the Platform or in emails to help us to
                    deliver cookies, count visits, understand usage and campaign effectiveness and determine whether an
                    email has been opened and acted upon. We may analyse or perform analysis based on the use of these
                    technologies and generate reports on an individual and aggregated basis.
                    <br />
                    <br /> Cookies can be &quot;persistent&quot; or &quot;session&quot; cookies. A persistent cookie
                    helps us recognize you as an existing user, so it is easier to return to the Platform or interact
                    with our services without signing in again. After you sign in, a persistent cookie stays in your
                    browser and will be read by the Platform when you return to the Platform. Session cookies only last
                    for as long as the session (usually the current visit to a website or a browser session).
                  </li>
                  <br />
                  <li>
                    <b> What are cookies used for?</b> <br />
                    <br />
                    When you visit the Platform, we may place a number of cookies in your web browser. These are known
                    as First Party Cookies and are required to enable to hold session information as you navigate from
                    page to page within the Platform. For example, we use cookies on our Platform to understand visitor
                    and user preferences, improve their experience, and track and analyse usage, navigational and other
                    statistical information. Additionally, cookies allow us to display advertisements both on and off
                    the Platform, and bring customized features to you. You can control the use of cookies at the
                    individual browser level. If you elect not to activate the cookie or to later disable cookies, you
                    may still visit the Platform, but your ability to use some features or areas of the Platform may be
                    limited. We may use any of the following categories of cookies on the Platform as detailed below.
                    Each cookie falls within one of the four following categories:
                  </li>
                  <br />
                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Essential Cookies</td>
                        <td>
                          Essential cookies (First Party Cookies) are sometimes called &quot;strictly necessary&quot; as
                          without them Company cannot provide certain services that you need on the Platform. For
                          example, essential cookies help remember your preferences as you browse around the Platform.
                          Essential cookies also keep you logged in into the Platform. Without these, the login
                          functionality would not work.
                        </td>
                      </tr>
                      <tr>
                        <td>Analytics Cookies</td>
                        <td>
                          These cookies track information about visits to the Platform and partner websites so that we
                          can make improvements and report our performance. For example: analyse visitor and user
                          behaviour so as to provide more relevant content or suggest certain activities. They collect
                          information about how visitors use the Platform, which website the user came from, the number
                          of each user’s visits and how long a user stays on the Platform. We might also use analytics
                          cookies to test new ads, pages, or features to see how users react to them.
                        </td>
                      </tr>
                      <tr>
                        <td>Functionality or Preference Cookies </td>
                        <td>
                          Functionality or Preference Cookies During your visit to the Platform, cookies are used to
                          remember information you have entered or choices you make (such as your username, language or
                          your region) on the Platform. They also store your preferences when personalizing the Platform
                          to optimize your use of Platform. These preferences are remembered, through the use of the
                          persistent cookies, and the next time you visit the Platform you will not have to set them
                          again.
                        </td>
                      </tr>
                      <tr>
                        <td>Targeting or Advertising Cookies</td>
                        <td>
                          These Cookies are placed by third party advertising platforms or networks or the Company in
                          order to, deliver ads and track ad performance, enable advertising networks to deliver ads
                          that may be relevant to you based upon your activities (this is sometimes called
                          &quot;behavioural&quot; &quot;tracking&quot; or &quot;targeted&quot; advertising on the
                          Platform. They may subsequently use information about your visit to target you with
                          advertising that you may be interested in on the Platform
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <li>
                    <b>When do we place cookies?</b>
                    <br />
                    <br />
                    When you access the Platform using any browser, we place cookies on such browser which helps us
                    identify you easily when you access the Platform for the next time. Cookies help us to determine
                    which pages or information you find most useful or interesting on the Platform. The Platform may
                    also have some cookies for its own analytics i.e. analytics not part of Google Analytics. Such
                    cookies help generate statistics and reports about visitors and their activities on the Platform.
                  </li>
                  <br />
                  <li>
                    <b>How cookies are used for online analytics purposes?</b> <br />
                    <br />
                    We may use web analytics services on the Platform, such as those of Google Analytics. These services
                    help us analyse how you use the Platform and the services offered therein, including by noting the
                    third party website from which you arrive. The information collected will be disclosed to or
                    collected directly by such service providers, who use the information to evaluate your use of the
                    Platform and services. We also use Google Analytics for certain purposes related to online
                    marketing, as described in the following sections.
                  </li>
                  <br />
                  <li>
                    <b>How are cookies used for advertising purposes? </b>
                    <br />
                    <br />
                    Cookies and other ad technology such as beacons, pixels, and tags help us serve relevant ads to you
                    more effectively. They also help us provide aggregated auditing, research, and reporting for
                    advertisers, understand and improve our services, and know when content has been displayed to you.
                    We work with website analytics and advertising partners, including Google Display Network, Facebook,
                    etc. to deliver advertisements on third party publisher websites, these partners may set cookies on
                    the web browser of your device. These cookies allow our partners to recognize your device so that
                    the ad server can show you advertisements elsewhere on the internet, and so that our analytics
                    software can measure your engagement and interactions while using services on the Platform. In this
                    way, ad servers may compile anonymous, de-identified information about where you, or others who are
                    using your device, saw our advertisements, whether or not you interacted with our advertisements,
                    and actions performed on subsequent visits to the Platform. This information allows an ad network to
                    deliver targeted advertisements that they believe will be of most interest to you, and it allows the
                    Platform to optimize the performance of our advertising campaigns and the usability of the Platform.
                    In other words, we use analytics data in concert with data about our online ads that have been shown
                    using the Google Display Network, Facebook for Advertisers, or other online advertising networks. By
                    doing so, we can understand how anonymous users interacted with the Platform after seeing our ads.
                  </li>
                  <br />
                  <li>
                    <b>What third-party cookies do we use? </b>
                    <br />
                    <br />
                    Please note that the third parties (advertising networks and providers of external services like web
                    traffic analysis services) may also use cookies on our services. Also, note that the names of
                    cookies, pixels and other technologies may change over time.
                    <br />
                    <br /> We use trusted partners to help us service advertising, who may place cookies on your device.
                    We also gather content from social networks into our own pages, such as embedded Facebook feeds. The
                    social networks, such as Facebook, Google, etc. may themselves also put cookies on your device. If
                    you log into Facebook, Twitter or Google+ via our website, they will leave a cookie on your device.
                    This is the same process as if you log into these social networks directly.
                    <br />
                    <br /> We also use Google Analytics on our Platform to help us analyse how services are used by you.
                    Google Analytics uses performance cookies to track customer’s interactions. For example, by using
                    cookies, Google can tell us which pages you have viewed, which are most popular for you, what time
                    of day the Platform is visited, whether you have visited the Platform before, which website referred
                    or redirected you to our Platform, and other similar information. All such information is
                    anonymized. <br />
                    <br />
                    We suggest that you should check the respective privacy policies for these external services to help
                    you understand what data these organisations hold about you and how they process it. <br />
                    <br />
                    Facebook:
                    <a className="a-link" href="Facebook">
                      https://www.facebook.com/policy.php
                    </a>{" "}
                    <br />
                    <br />
                    AdSense:
                    <a className="a-link" href="Adsense">
                      https://policies.google.com/technologies/ads
                    </a>{" "}
                    <br />
                    <br />
                    Google Analytics:
                    <a className="a-link" href="Google Analytics">
                      https://www.google.com/analytics/learn/privacy.html{" "}
                    </a>
                    <br />
                    <br />
                    Google Tag Manager:{" "}
                    <a className="a-link" href="Google Tag Manager">
                      https://www.google.com/analytics/tag-manager/faq/
                    </a>{" "}
                    <br />
                    <br />
                    Google+:{" "}
                    <a className="a-link" href="Google+">
                      https://www.google.com/policies/privacy/
                    </a>{" "}
                    <br />
                    <br />
                    Twitter:
                    <a className="a-link" href="Twitter">
                      {" "}
                      https://twitter.com/en/privacy
                    </a>{" "}
                    <br />
                    <br />
                    Google Double click:
                    <a className="a-link" href="Google Double click">
                      {" "}
                      https://support.google.com/dfp_premium/answer/2839090?hl=en
                    </a>
                    <br />
                    <br />
                  </li>
                  <li>
                    What are Web Beacons? <br />
                    Company also gets advertised on third party websites. As part of our effort to track the success of
                    our advertising campaigns, we may at times use a visitor identification technology such as &quot;web
                    beacons,&quot; or &quot;action tags&quot; which count visitors who have accessed the Platform after
                    visiting an advertisement on a third party site. Our partners may also use web beacons to collect
                    data in the aggregate as provided by your browser while you are on our Platform or another website
                    where Company is a third party.
                  </li>
                  <br />
                  <li>
                    <b>How to control cookies?</b>
                    <br />
                    <br />
                    Most browsers allow you to control cookies through their settings preferences. However, if you
                    choose to turn off these cookies, you will still see advertising on the internet but it may not be
                    customised to your interests. It does not mean that you will not be served any advertisements whilst
                    you are online. Whilst we have mentioned most of the third parties involved in using targeting or
                    advertising cookies in the preceding sections, the list is not exhaustive and is subject to change.
                    Therefore, even if you choose to turn off all the third party cookies, you may still receive some
                    customised advertisements and recommendations.
                    <br />
                    <br /> There are a number of ways you can manage what cookies are set on your devices. Essential
                    cookies, however, cannot be disabled. If you do not allow certain cookies to be installed, the
                    Platform may not be accessible to you and the performance, features, or services of the Platform may
                    be compromised.
                    <br />
                    <br /> You can also manage this type of cookie in the privacy settings on your web browser. Please
                    note that if you use your browser settings to block all cookies you may not be able to access parts
                    of the Platform or third party websites. Please see below for more information.
                    <br />
                    <br /> <b>Changing your Cookie Settings:</b> The browser settings for changing your cookies settings
                    are usually found in the &aposoptions&apos or &apospreferences&apos menu of your internet browser.
                    In order to understand these settings, the following links may be helpful. Otherwise you should use
                    the &aposHelp&apos option in your web browser for more details.
                  </li>
                  <br />
                  <ul type="disk">
                    <li>
                      <u>Cookie settings in Internet Explorer</u>
                    </li>
                    <li>
                      <u>Cookie settings in Firefox</u>
                    </li>
                    <li>
                      <u>Cookie settings in Chrome</u>
                    </li>
                    <li>
                      <u>Cookie settings in Safari</u>
                    </li>
                  </ul>
                  <br />
                  <li>
                    <b>Contact us.</b>
                    <br />
                    <br />
                    If you have any questions regarding this Cookie Policy, please contact{" "}
                    <a className="a-link" href="link">
                      privacy@infinitylearn.com.
                    </a>
                  </li>
                </ol>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
