import React from "react";
import HeroSection from "./HeroSection";
import WhySection from "./WhySection";
import EvetnInfoSection from "./EvetnInfoSection";
import FAQSection from "./FAQSection";

const IdlLandingPage = () => {
  return (
    <>
      <HeroSection />
      <WhySection />
      <EvetnInfoSection />
      <FAQSection />
    </>
  );
};

export default IdlLandingPage;
