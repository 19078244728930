import React from "react";

export const MembershipBenefitsShapes = () => {
  return (
    <svg width="189" height="269" viewBox="0 0 189 269" fill="none">
      <ellipse
        cx="10.3499"
        cy="104.285"
        rx="145.5"
        ry="109"
        transform="rotate(-25.6583 10.3499 104.285)"
        fill="white"
        fillOpacity="0.02"
      />
      <g filter="url(#filter0_d_12_68530)">
        <ellipse
          cx="52.3485"
          cy="157.785"
          rx="44.5"
          ry="122.5"
          transform="rotate(-41.1118 52.3485 157.785)"
          fill="#F4C469"
          fillOpacity="0.01"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_12_68530"
          x="-43.9219"
          y="60.9375"
          width="192.539"
          height="223.695"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="21" />
          <feGaussianBlur stdDeviation="4.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12_68530" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12_68530" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
