import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { userLogout } from "../../store/auth/auth.slice";
import { ROUTES } from "../../constants/routes";

export const SessionTimeout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const timeout = 20 * 60 * 1000;

  useEffect(() => {
    const handleLogout = () => {
      dispatch(userLogout());
      sessionStorage.removeItem("lastActiveTime");
      history.push(ROUTES.HOME);
    };

    const resetTimeout = () => {
      const currentTime = Date.now();
      sessionStorage.setItem("lastActiveTime", currentTime.toString());
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    const addEventListeners = () => {
      ["mousemove", "keydown", "scroll", "mousedown", "touchstart"].forEach((event) => {
        window.addEventListener(event, handleUserActivity);
      });
    };

    const removeEventListeners = () => {
      ["mousemove", "keydown", "scroll", "mousedown", "touchstart"].forEach((event) => {
        window.removeEventListener(event, handleUserActivity);
      });
    };

    const checkTimeout = () => {
      const lastActiveTime = sessionStorage.getItem("lastActiveTime");
      const currentTime = Date.now();
      const storedLastActiveTime = parseInt(lastActiveTime, 10);
      if (currentTime - storedLastActiveTime > timeout) {
        handleLogout();
      }
    };

    addEventListeners();
    resetTimeout();
    const interval = setInterval(checkTimeout, 1000);

    return () => {
      clearInterval(interval);
      removeEventListeners();
    };
  }, [timeout, dispatch, history]);

  return null;
};
