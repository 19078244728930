import React from "react";
import back from "../../../assets/svg/reportspage/plainleft.svg";

const Popup = ({ show, onClose, children, ispdf, pdf, examType }) => {
  if (!show) return null;

  const handleOutsideClick = (e) => {
    // Close when clicking outside of the popup content (if not in PDF mode)
    if (e.target.className.includes("Rep-popup-cont") || e.target.className.includes("Rep-popup-overlay")) {
      onClose();
    }
  };

  return (
    <div className={`Rep-popup-cont `} onClick={handleOutsideClick}>
      <div onClick={onClose} style={{ color: "#FFF", cursor: "pointer", display: "flex", gap: "5px" }}>
        <img style={{ width: "3rem" }} src={back} />

        {ispdf && <span style={{ marginTop: "14px" }}> {examType.toUpperCase()} Sample Detailed Report</span>}
      </div>

      <div className={`Rep-popup-overlay `} onClick={handleOutsideClick}>
        {!ispdf ? (
          <div className={`Rep-popup-content ${ispdf ? "pdf-content" : ""}`}>{children}</div>
        ) : (
          <iframe
            className="Rep-popup-content.pdf-content"
            src={`${pdf}#toolbar=0`} // Assuming the PDF is placed in the public folder
            width="80%"
            height="90%"
            type="application/pdf"
            style={{ background: "transparent" }}
          />
        )}
      </div>
    </div>
  );
};

export default Popup;
