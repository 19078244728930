import queryString from "query-string";
import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { schoolRequestCodeResolver } from "../../../../constants/resolvers";
import { useRequestCodeMutation } from "../../../../store/auth/auth.api";
import { phoneNumberSchema } from "../../../../constants/schemas";
import { useSendOTPMutation, useVerifyOTPMutation } from "../../../../store/auth/otp.api";
import { CONFIG_VARS } from "../../../../constants/configuration";
import { useTimer } from "react-timer-hook";
import OtpInput from "react-otp-input";
import { PhoneInput } from "../../../components/form/PhoneInput";
import { schoolCreateAccFormFields } from "../../../../constants/constants";
let { firstName, emailId, schoolName, phone, TC } = schoolCreateAccFormFields;
import loaderGif from "../../../../assets/gifs/LoaderGif.gif";
import { setModalState } from "../../../../store/app/app.slice";
import { RequestActivationCodeContent } from "../../../components/modal/RequestActivationCode";
import { useDispatch } from "react-redux";

const defaultValues = () => ({
  otpVerified: false,
  formLoginFlag: false,
  email: ""
});

export const SchoolRequestCode = ({ toggleTAndC, togglePAndP }) => {
  const { CODE = null } = queryString.parse(useLocation().search);
  // const ref = useRef();

  //const [verifyEmail, { isSuccess: verifyEmailIsSuccess }] = useVerifyEmailMutation();
  const [verifyOTP, { isSuccess: verifyOTPIsSuccess }] = useVerifyOTPMutation();

  // const [invitationCodeError, setInvitationCodeError] = useState(null);
  // const [emailError, setEmailError] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [timerExpired, setTimerExpired] = useState(false);
  const [isTimerCompleted, setIsTimerCompleted] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [otpValue, setOtpValue] = useState(Array(4).fill(""));
  const [loader, setLoader] = useState(false);
  const [onRegister] = useRequestCodeMutation();

  const [sendOTP, { isLoading: sendOTPLoading, isUninitialized }] = useSendOTPMutation();
  const { restart, seconds, isRunning } = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => {
      setTimerExpired(true);
      setIsTimerCompleted(true);
    }
  });

  const form = useForm({
    defaultValues: defaultValues({ CODE }),
    resolver: schoolRequestCodeResolver,
    mode: "onChange"
  });
  const { formState } = form;
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setError,
    clearErrors,
    setValue
  } = form;
  const { isSubmitting } = formState;
  //const [invitationCode] = watch(["invitationCode"]);
  const defaultCheck = watch(["firstName", "lastName", "email", "schoolName", "otpCode", "phoneNumber"]);
  const [phoneNumber, ...otpCodes] = watch(["phoneNumber", ...Array(4).fill("otpCode")]);
  const phoneNumberIsValid = useMemo(() => phoneNumberSchema.isValidSync(phoneNumber), [phoneNumber]);
  // const handleInputPaste = (event) => {
  //   const pastedText = event.clipboardData.getData("text/plain");
  //   const sanitizedText = pastedText.replace(/\s/g, ""); // Remove spaces from pasted text
  //   event.preventDefault();
  //   document.execCommand("insertText", false, sanitizedText); // Insert sanitized text
  // };

  // useEffect(() => {
  //   checkValidCode();
  // }, [invitationCode]);

  // useEffect(() => {
  //   if (CODE) {
  //     checkValidCode();
  //   }
  // }, []);
  useEffect(() => {
    if (timerExpired && isOtpSent) {
      setTimerExpired(false);
    }
    if (timerExpired && !isOtpSent) {
      setShowOtpInput(false); // Hide OTP input
      setTimerExpired(false);
    }
  }, [timerExpired, isOtpSent]);

  // const checkEmail = async (email) => {
  //   if (emailSchema.isValidSync(email)) {
  //     console.log("1");
  //     const result = await verifyEmail(email);
  //     console.log(result.data);
  //     if (result.data.exist) {
  //       emailId = false;
  //       console.log("2");
  //       setError("email", { message: "Email is already registered with us" });
  //     } else {
  //       emailId = true;
  //       console.log("3");
  //       setError("email", { message: "Email is already registered with us" });
  //       clearErrors("email");
  //     }
  //   } else {
  //     console.log("5");
  //     emailId = false;
  //   }
  // };
  const dispatch = useDispatch();
  const showModal = () => {
    dispatch(setModalState({ title: "Acknowledgement", content: <RequestActivationCodeContent /> }));
  };

  const clickSendOTP = async () => {
    setOtpValue(Array(4).fill(""));
    setError("otpCode", { type: "custom", message: "" });
    setIsButtonDisabled(true);
    await trigger("phoneNumber");
    setIsTimerCompleted(false);

    if (phoneNumberIsValid) {
      const result = await sendOTP({ phoneNumber });

      if (result?.data) {
        const time = new Date();
        time.setSeconds(time.getSeconds() + 30);
        restart(time);
        setIsEditEnabled(true);
      }

      if (result?.error) {
        // Handle error
      }
    }
  };

  const handleSignUp = async () => {
    const otpString = otpValue.join("");
    setIsTimerCompleted(true);
    await trigger("otpCode");

    if (otpString.length === 4) {
      setValue("otpCode", otpString);
      const result = await verifyOTP({ otpCode: otpString, phoneNumber });
      console.log(result);
      if (result?.data?.detail?.includes("user does not exist")) {
        setError("phoneNumber", { type: "custom", message: "Mobile number is not registered. Please register." });
      } else if (result?.error?.data?.message) {
        setError("otpCode", { type: "custom", message: "Incorrect OTP, please try again." });
        setIsButtonDisabled(true);
      } else if (result?.data) {
        const formData = form.getValues();
        const res = await onRegister(formData);
        console.log(res);
        if (res.data.detail) {
          setError("email", { type: "custom", message: "Email is already registered with us" });
        }
      } else {
        setIsButtonDisabled(false);
      }
    } else {
      setError("otpCode", { type: "custom", message: "OTP must be exactly 4 digits." });
    }
  };

  const handleButtonAction = async () => {
    if (!isOtpSent) {
      // Send OTP on the first click
      setLoader(true);
      await clickSendOTP();
      setIsDisable(true);
      setIsOtpSent(true);
      setIsTimerCompleted(false);
      setShowOtpInput(true);
      setLoader(false);
      setIsButtonDisabled(true);
    } else {
      // Handle OTP verification and signup
      setLoader(true);
      await handleSignUp();
      setLoader(false);
    }
  };
  const handleEditNumberClick = () => {
    setIsDisable(false);
    setIsButtonDisabled(true);
    setIsEditEnabled(false);
    setShowOtpInput(false); // hiding OtpInput box
    setIsOtpSent(false); // disable button and setting back to the send otp state
    setValue("phoneNumber", ""); // Clear the phone number field
    setOtpValue(Array(4).fill("")); // Clear the OTP input field
    clearErrors("phoneNumber"); // Clear any errors related to the phone number field
    Array(4)
      .fill("")
      .forEach((_, index) => {
        clearErrors(`otpCode[${index}]`); // Clear any errors related to the OTP input field
      });
    restart();
    setIsTimerCompleted(true);
  };
  const handlePhoneNumberChange = async (phoneNumber) => {
    if (phoneNumber.length != 10) {
      phone = false;
      //setError("phoneNumber", { message: "Phone number must be at least 10 characters" });
      //setIsButtonDisabled(false); // Disable the button if phone number is invalid
    }
  };
  const onBlur = async (fieldName) => {
    await trigger(fieldName);
  };

  const handleOtpCodeChange = (e) => {
    const enteredOtp = e.trim(); // Remove leading/trailing spaces

    if (enteredOtp.length === 4) {
      const otpFormatIsValid = /^[0-9]{4}$/.test(enteredOtp);
      if (otpFormatIsValid) {
        setOtpValue([...enteredOtp]);
        clearErrors("otpCode");
        const isAllFieldsFilled = firstName && schoolName && phone && TC && emailId; // Check other fields for validity here

        setIsButtonDisabled(!isAllFieldsFilled);
      } else {
        setError("otpCode", { type: "custom", message: "OTP must be exactly 4 digits." });
        setIsButtonDisabled(true);
      }
    } else {
      setOtpValue([...enteredOtp]);
      setError("otpCode", { type: "custom", message: "OTP must be exactly 4 digits." });
      setIsButtonDisabled(true);
    }
  };

  const disabled = useMemo(() => {
    return {
      phoneForm: isRunning || sendOTPLoading || isSubmitting,
      otpForm: !(isRunning || !isUninitialized) || isSubmitting
    };
  }, [isRunning, isUninitialized, sendOTPLoading, isSubmitting, phoneNumberIsValid]);
  const onSubmit = async (data) => {
    if (verifyOTPIsSuccess) {
      const res = await onRegister(data);

      if (res.data === null) {
        showModal();
      } else if (res.error.data) {
        setError("email", { type: "custom", message: res.error.data.detail });
      } /* else if (res.data.detail) {
        setError("phoneNumber", { type: "custom", message: res.data.detail });
      } else if (res.data.message) {
        setError("invitationCode", { type: "custom", message: res.data.message });
      } */
    } else {
      setError("otpCode", { type: "custom", message: "Otp code must be verified!" });
    }
  };

  const handleInputKeyPress = async (event, field) => {
    event.preventDefault();
    // console.log(field.value);
    switch (field.name) {
      case "firstName":
        // eslint-disable-next-line no-case-declarations
        const nameValue = field.value.replace(/[^A-Za-z\s]/gi, "");
        setValue(field.name, nameValue);
        firstName = nameValue.length > 1;
        break;
      case "lastName":
        // eslint-disable-next-line no-case-declarations
        const lastNameValue = field.value.replace(/[^A-Za-z\s]+/gi, "");
        setValue(field.name, lastNameValue);
        break;
      case "email":
        // eslint-disable-next-line no-case-declarations
        const mailValue = field.value.replace(/\s/g, "");
        setValue("email", mailValue);
        //checkEmail(mailValue);
        // if (/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,9}$/i.test(mailValue)) {
        //   emailId = true;
        // } else {
        //   emailId = false;
        // }
        break;
      case "schoolName":
        // eslint-disable-next-line no-case-declarations
        const schoolValue = field.value.replace(/[^A-Za-z\s]/gi, "");
        setValue(field.name, schoolValue);
        schoolName = field.value.length > 1;
        break;
      case "whatsappConsent":
        TC = field?.checked;
        break;
      case "phoneNumber":
        // setValue(field.name, field.value);
        // phone = field.value.length === 10;
        handlePhoneNumberChange(field.value);
        break;
      default:
        break;
    }
    const isAllFieldsFilled = firstName && schoolName && phone && TC && emailId;
    (otpValue.join("").length === 4 || !isOtpSent) && setIsButtonDisabled(!isAllFieldsFilled);
  };

  return (
    <form onChange={(e) => handleInputKeyPress(e, e.target)} onSubmit={handleSubmit(onSubmit)}>
      <div className="membership__card membership__card_mob mx-auto ms-md-auto">
        <div className="tab-content">
          <h3 className="tab-heading">Apply for Membership </h3>
          <div className="row">
            <div className="col-6 pe-2 mb-12">
              <label className="tab-lable">First name</label>

              <input
                type="text"
                required
                className={`member--inputbox ${errors.firstName ? "error--strike" : "input-highlight"}`}
                {...register("firstName")}
                disabled={isDisable}
              />
              {errors.firstName && <span className="error--strike--text">{errors.firstName.message}</span>}
            </div>
            <div className="col-6 ps-2 mb-12">
              <label className="tab-lable">Last name</label>

              <input
                type="text"
                // required
                className={`member--inputbox ${errors.lastName ? "error--strike" : ""}`}
                {...register("lastName")}
                disabled={isDisable}
              />
              {errors.lastName && <span className="error--strike--text">{errors.lastName.message}</span>}
            </div>
            <div className="col-12 mb-12">
              <label className="tab-lable">Email ID</label>

              <input
                // type="email"
                required
                // onInput={handleInputChange}
                className={`member--inputbox ${errors.email ? "error--strike" : "input-highlight"} `}
                placeholder="Enter your email id"
                {...register("email")}
                disabled={isDisable}
              />
              {/* {emailError && <span className="error--strike--text">{emailError}</span>} */}
              {errors.email && <span className="error--strike--text">{errors.email.message}</span>}
            </div>
            <div className="col-12 mb-12">
              <label className="tab-lable">School Name</label>

              <input
                type="text"
                required
                className={`member--inputbox ${errors.schoolName ? "error--strike" : "input-highlight"}`}
                placeholder="Enter name"
                {...register("schoolName")}
                disabled={isDisable}
              />
              {errors.schoolName && <span className="error--strike--text">{errors.schoolName.message}</span>}
            </div>

            <div className="position-relative">
              <label className="tab-lable">Phone Number</label>
              <PhoneInput
                form={form}
                disabled={isEditEnabled}
                handleChange={handlePhoneNumberChange}
                onBlur={() => onBlur("phoneNumber")}
                handleEditNumberClick={handleEditNumberClick}
                isEditEnabled={isEditEnabled}
              />
              {errors.phoneNumber && <span className="error--strike--text">{errors.phoneNumber.message}</span>}
              {disabled.verifyButton &&
                otpCodes.map((_, index) => {
                  return (
                    errors[`otpCode[${index}]`] && (
                      <span className="error--strike--text" key={index}>
                        {errors[`otpCode[${index}]`].message}
                      </span>
                    )
                  );
                })}
            </div>
            {showOtpInput && (
              <div>
                <label className="tab-lable">Enter OTP</label>
                <div className="col-12 position-relative mb-12">
                  <div className="d-flex align-items-center">
                    {/* Replaced the individual input fields with OtpInput */}
                    <OtpInput
                      inputType="number"
                      skipDefaultStyles={false}
                      shouldAutoFocus="false"
                      value={otpValue.join("")}
                      onChange={(e) => handleOtpCodeChange(e)}
                      numInputs={4}
                      separator={<span> </span>}
                      isDisabled={disabled.otpForm}
                      inputStyle="otp-input-box"
                      renderInput={(props, index) => (
                        <input
                          {...props}
                          key={index}
                          // type="text"
                          required
                          className={`otp-input-box ${errors.otpCode?.message ? "otp-error-strick" : "highlight"}`}
                        />
                      )}
                    />
                    <div className="resend_otp">
                      {!isTimerCompleted && (
                        <span>
                          Resend OTP <br />({seconds} Sec.)
                        </span>
                      )}
                      {isTimerCompleted && (
                        <button className="resend-btn" onClick={clickSendOTP}>
                          Resend OTP
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {errors.otpCode && <span className="error--strike--text">{errors.otpCode.message}</span>}
                {/* {otpCodes.map((_, index) => {
                  return (
                    errors[`otpCode[${index}]`] && (
                      <button className="error--strike--text" key={index}>
                        {errors[`otpCode[${index}]`].message}
                      </button>
                    )
                  );
                })} */}
              </div>
            )}
          </div>

          <div className="col-12 mb-3">
            <div className="form-check check-opacity">
              <label className="form-check-label text-muted">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="whatsappConsent"
                  {...register("whatsappConsent")}
                  defaultChecked={defaultCheck}
                />
                <i className="input-helper"></i> Notify me on WhatsApp <br />
              </label>
              <br />
              <span className="t-c_class">
                By Signing up, you agree with our{" "}
                <span onClick={toggleTAndC} className="gard--textflow mx-1">
                  T&C
                </span>{" "}
                and{" "}
                <span className="gard--textflow mx-1" onClick={togglePAndP}>
                  PP
                </span>
              </span>
              <br />
              {errors.whatsappConsent && <span className="error--strike--text">This field is required</span>}
            </div>
          </div>

          <button className="button--membersubmit w-100" onClick={handleButtonAction} disabled={isButtonDisabled}>
            {isOtpSent ? (
              loader ? (
                <div className="loader-container">
                  <img className="loader-gif" src={loaderGif} alt="Loading.." />
                  <p className="loader-text">Loading</p>
                </div>
              ) : (
                "Continue"
              )
            ) : loader ? (
              <div className="loader-container">
                <img className="loader-gif" src={loaderGif} alt="Loading.." />
                <p className="loader-text">Loading</p>
              </div>
            ) : (
              "Continue"
            )}
          </button>
          <div className="col-12 mb-3">
            <p className="login--supporttext">
              Already having account?
              {CONFIG_VARS.schoolLoginUrl ? (
                <span
                  className="gard--textflow"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.location.assign(CONFIG_VARS.schoolLoginUrl);
                  }}
                >
                  &nbsp;Login
                </span>
              ) : (
                <Link className="gard--textflow mx-1" to={ROUTES.LOGIN}>
                  Login here
                </Link>
              )}
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};
