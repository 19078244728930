import React from "react";

import studentsBig from "../../assets/images/frontside/students-big.svg";
import Schoolillution from "../../assets/svg/SchoolIllustartion.svg";
//import parentsBig from "../../assets/images/frontside/parents-big.svg";

import { MembershipBenefitsShapes } from "../../assets/svg/MembershipBenefitsShapes";
import { useTranslation } from "react-i18next";

export const MembershipBenefitsSection = () => {
  const { t } = useTranslation();
  return (
    <section className="exp__exellence membership__benefits">
      <div className="container">
        <h3 className="exp__exellence--heading text-md-start">{t("home_page.p3_heading")}</h3>
        <div className="membership__benefits--grid">
          <div className="membership__benefits--item membership__benefits--students">
            <div className="grad11"></div>
            <div className="grad12"></div>
            <span className="membership__benefits--shapes">
              <MembershipBenefitsShapes />
            </span>
            <div className="position-relative z1">
              <div className="membership__benefits--gridbox">
                <div>
                  <h4 className="membership__benefits--title">{t("home_page.p3_block1_header")}</h4>
                  <ol className="ol--list">
                    <li>{t("home_page.p3_block1_item1")}</li>
                    <li>{t("home_page.p3_block1_item2")}</li>
                    <li>{t("home_page.p3_block1_item3")}</li>
                    {/* <li>{t("home_page.p3_block1_item4")}</li>
                    <li>{t("home_page.p3_block1_item5")}</li>
                    <li>{t("home_page.p3_block1_item6")}</li>
                    <li>{t("home_page.p3_block1_item7")}</li> */}
                  </ol>
                </div>
                <div className="text-center">
                  <img src={studentsBig} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="membership__benefits--item membership__benefits--teachers">
            <div className="grad11"></div>
            <div className="grad12"></div>
            <span className="membership__benefits--shapes">
              <MembershipBenefitsShapes />
            </span>
            <div className="position-relative z1">
              <div className="membership__benefits--gridbox">
                <div>
                  <h4 className="membership__benefits--title">{t("home_page.p3_block2_header")}</h4>
                  <ol className="ol--list">
                    <li>{t("home_page.p3_block2_item1")}</li>
                    <li>{t("home_page.p3_block2_item2")}</li>
                    <li>{t("home_page.p3_block2_item3")}</li>
                    {/* <li>{t("home_page.p3_block2_item4")}</li>
                    <li>{t("home_page.p3_block2_item5")}</li> */}
                  </ol>
                </div>
                <div className="text-center">
                  <img src={Schoolillution} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="membership__benefits--item membership__benefits--parents"> 
            <div className="grad11"></div>
            <div className="grad12"></div>
            <span className="membership__benefits--shapes">
              <MembershipBenefitsShapes />
            </span>
            <div className="position-relative z1">
              <div className="membership__benefits--gridbox">
                <div>
                  <h4 className="membership__benefits--title">Parents</h4>
                  <ol className="ol--list">
                    <li>Detailed insights on child’s performance in Score, Olympiad, etc.</li>
                    <li>
                      Personalized feedback and detailed analysis to help you identify your child’s strengths &
                      weaknesses
                    </li>
                    <li>Parenting Booklet</li>
                  </ol>
                </div>
                <div className="text-center">
                  <img src={parentsBig} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
    </section>
  );
};
