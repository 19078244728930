import React from "react";
import OnboardingPic from "../../../assets/images/frontend/onboardingpic.svg";
import { getUserInfo } from "../../../store/auth/selectors";
import { useSelector } from "react-redux";

export const StepStart = ({ goForward }) => {
  const user = useSelector(getUserInfo);

  return (
    <section className="main__wrapper">
      <div className="maxw--500 text-center mx-auto">
        <img src={OnboardingPic} alt="onboardingpic" />
        <h1 className="onboarding--name">
          Hello <strong>{`${user?.first_name} ${user?.last_name || ""}`}</strong>
        </h1>
        <p className="onboarding--text mt-3">Please complete the following basic setup of your school</p>
        <a href="#" onClick={goForward} className="startBtn w-100 mt-4">
          Start Now
        </a>
      </div>
    </section>
  );
};
