import React from "react";
export const RequestActivationCodeContent = () => {
  return (
    <div>
      <br />
      <br />
      <div className="banner__contentbox--text mt-2 pt-1">
        Your request for activation code has been acknowledged and we shall get back to you soon.
        <br />
        <br />
        <br />
        <br />
        <div className="modal-goback">
          <a href="." className="fill__navBtn">
            <span>Go Back</span>
          </a>
        </div>
      </div>
    </div>
  );
};
