import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "../../store/app/app.slice";
import { getMdm, getUserInfo } from "../../store/auth/selectors";
import { ResetPasswordModalContent } from "../components/modal/ResetPassword";
import { PersonalDetails } from "./PersonalDetails";
import { userLogout } from "../../store/auth/auth.slice";
import { ROUTES } from "../../constants/routes";
import { history } from "../../router/history";
import LogoutCTA from "../../assets/svg/LogoutCTA.svg";

const tabs = [
  { id: 1, title: "Personal details", content: PersonalDetails }
  // { id: 2, title: "Preferences", content: Preferences }
];

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const mdm = useSelector(getMdm);
  const { t } = useTranslation();

  //const [editMode, setEditMode] = useState(true);
  const [tabID, setTabID] = useState(tabs[0].id);

  const TabContent = useMemo(() => tabs.find((tab) => tab.id === tabID).content, [tabID]);

  const openChangePassModal = () => {
    dispatch(setModalState({ title: "reset password", content: <ResetPasswordModalContent /> }));
  };
  const pressLogout = () => {
    history.push(ROUTES.STUDENT);
    dispatch(userLogout());
  };

  return (
    <div className="container">
      <div className="student__onboarding__contentmenu">
        <div className="student__onboarding__innerbox">
          <div className="board__upcoming__box">
            <h4 className="board__top--heading">{t("profile_page")}</h4>
            <form className="mt-3">
              <div className="darkform__box">
                <div className="darkform__profilebox">
                  <div className="darkform__profilebox--pic position-relative">
                    <input type="file" id="fileUpload" hidden={true} />
                    <div className="inforbox__circle">
                      <img
                        id="blah"
                        src="https://t4.ftcdn.net/jpg/03/32/59/65/360_F_332596535_lAdLhf6KzbW6PWXBWeIFTovTii1drkbT.jpg"
                        alt="profile-pic"
                      />
                    </div>
                  </div>
                  <div className="darkform__profilebox--content pt-2">
                    <h4 className="profile_name_text">
                      {userInfo.first_name} {userInfo.last_name}
                    </h4>
                    <p className="profileb-grade-text">Grade-{mdm?.grade_id}</p>
                  </div>
                </div>
                <div className="darkform__box__detailbar d-flex justify-content-between align-items-center">
                  {/* Change password link aligned to left */}
                  <div className="text-start">
                    <a href="#" onClick={openChangePassModal} className="change_password">
                      Change password
                    </a>
                  </div>
                  {/* Log Out paragraph aligned to right */}
                  <div className="text-end">
                    <p className="mb-0">
                      <a href="#" onClick={pressLogout} className="change_password">
                        <img src={LogoutCTA} />
                        Log Out
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="custom__personalbox  border-dow mb-5">
                <div className="row">
                  <div className="col-md-8">
                    <ul className="nav nav-pills " id="pills-tab" role="tablist">
                      {tabs.map(({ id, title }) => (
                        <li key={"tabID" + id} className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${tabID === id ? "active" : ""} personal-details`}
                            type="button"
                            onClick={() => setTabID(id)}
                          >
                            {title}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-4 mt-3 mt-md-0 text-end">
                    {/* <button type="button" className="profile--saveBtn" onClick={() => setEditMode((prev) => !prev)}>
                      <span className="profile--saveBtntxt">{editMode ? "Save" : "Edit"}</span>
                    </button> */}
                  </div>
                </div>
                <div className="tab-content mt-sm-4 pb-md-5">
                  <TabContent />
                </div>
                {/*<div className="pt-1 text-end">*/}
                {/*  <button type="submit" className="submitBtn--dark">*/}
                {/*    <LogOutIcon />*/}
                {/*    <span>Log Out</span>*/}
                {/*  </button>*/}
                {/*</div>*/}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
