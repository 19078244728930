import React from "react";

export const StepTwo = ({ setCount, form }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors }
  } = form;
  const handelCount = (index) => (e) => {
    const value = e.target.value;
    setCount((prevCounts) => {
      const updatedCounts = [...prevCounts];
      updatedCounts[index] = value;
      return updatedCounts;
    });
  };
  const [gradeWise] = watch(["grade_wise"]);

  const toggleGradeWise = () => {
    setValue("grade_wise", !gradeWise);
  };

  return (
    <div className="row align-items-center">
      <div className="col-md-6">
        <h3 className="modal-title">Total Count</h3>
      </div>
      <div className="col-md-6">
        <p className="edit__access font--0 mb-0">
          Grade wise
          <button
            onClick={toggleGradeWise}
            type="button"
            className={`btn-toggle tg--icon ${gradeWise ? "active" : ""}`}
          >
            <div className="handle"></div>
          </button>
        </p>
      </div>
      <div className="col-12 mt-4">
        {!gradeWise && (
          <div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label htmlFor="" className="form__select--label">
                  Student count<sub>*</sub>
                </label>
                <input
                  type="number"
                  className={`form__input--box w-100 ${errors.total_student_count ? "error--strike" : ""}`}
                  {...register("total_student_count")}
                  onChange={handelCount(0)}
                />
                {errors.total_student_count && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="" className="form__select--label">
                  Teacher count
                </label>
                <input
                  type="number"
                  className={`form__input--box w-100 ${errors.total_teacher_count ? "error--strike" : ""}`}
                  {...register("total_teacher_count")}
                  onChange={handelCount(1)}
                />{" "}
                {errors.total_teacher_count && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-12">
                <label htmlFor="" className="form__select--label">
                  Other staff count
                </label>
                <input
                  type="number"
                  className={`form__input--box w-100 ${errors.other_staff_count ? "error--strike" : ""}`}
                  {...register("other_staff_count")}
                  onChange={handelCount(2)}
                />{" "}
                {errors.other_staff_count && <span className="error--strike--text">Field is required</span>}
              </div>
            </div>
          </div>
        )}

        {gradeWise && (
          <div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Grade 12<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.grade_12")} />
                {errors?.grade_wise_count?.grade_12 && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Grade 11<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.grade_11")} />
                {errors?.grade_wise_count?.grade_11 && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Grade 10<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.grade_10")} />
                {errors?.grade_wise_count?.grade_10 && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Grade 09<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.grade_9")} />
                {errors?.grade_wise_count?.grade_9 && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Grade 08<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.grade_8")} />
                {errors?.grade_wise_count?.grade_8 && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Grade 07<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.grade_7")} />
                {errors?.grade_wise_count?.grade_7 && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Grade 06<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.grade_6")} />
                {errors?.grade_wise_count?.grade_6 && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Grade 05<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.grade_5")} />
                {errors?.grade_wise_count?.grade_5 && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Grade 04<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.grade_4")} />
                {errors?.grade_wise_count?.grade_4 && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Grade 03<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.grade_3")} />
                {errors?.grade_wise_count?.grade_3 && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Grade 02<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.grade_2")} />
                {errors?.grade_wise_count?.grade_2 && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Grade 01<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.grade_1")} />
                {errors?.grade_wise_count?.grade_1 && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  UKG<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.ukg")} />
                {errors?.grade_wise_count?.ukg && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  LKG<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.lkg")} />
                {errors?.grade_wise_count?.lkg && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form__select--label">
                  Nursery<sub>*</sub>
                </label>
                <input type="number" className="form__input--box w-100" {...register("grade_wise_count.nursery")} />
                {errors?.grade_wise_count?.nursery && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-12 pt-4 mb-2">
                <h3 className="modal-title">Teacher and staff count</h3>
              </div>
              <div className="col-md-6">
                <label htmlFor="" className="form__select--label">
                  Teacher count
                </label>
                <input type="number" className="form__input--box w-100" {...register("total_teacher_count")} />
                {errors?.total_teacher_count && <span className="error--strike--text">Field is required</span>}
              </div>
              <div className="col-md-6">
                <label htmlFor="" className="form__select--label">
                  Staff count
                </label>
                <input type="number" className="form__input--box w-100" {...register("other_staff_count")} />
                {errors?.other_staff_count && <span className="error--strike--text">Field is required</span>}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
