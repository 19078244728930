import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG_VARS } from "../constants/configuration";

const { oms_url } = CONFIG_VARS;

export const omsMutation = createApi({
  reducerPath: "omsMutation",
  baseQuery: fetchBaseQuery({
    oms_url: oms_url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth?.tokens?.access_token;
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      headers.set("x-tenant", "infinitylearn");
      return headers;
    }
  }),
  endpoints: (build) => ({
    getOmsPackages: build.mutation({
      query: (packageId) => ({
        url: `${oms_url}api/packages/v2/get_package_and_subscription_detail/${packageId}`,
        method: "GET",
        headers: {
          "Ot-Tenant": "infinitylearn"
        }
      })
    }),
    getAllCartItems: build.mutation({
      query: (data) => ({
        url: `${oms_url}api/v2/cart/getCartItem/${data?.userId}/${data?.grade}`,
        method: "GET"
      })
    }),
    saveCartItem: build.mutation({
      query: (data) => ({
        url: `${oms_url}api/v2/cart/saveCartItem`,
        method: "POST",
        headers: {
          "x-platform": "web"
        },
        body: data
      })
    }),
    calculateDiscount: build.mutation({
      query: (data) => ({
        url: `${oms_url}api/couponsV1s/caculateDiscount?couponsCode=${data?.coupons}&grade=${data?.grade}&userId=${data?.userID}`,
        method: "GET",
        headers: {
          "x-platform": "web"
        }
      })
    }),
    createUserCartOrder: build.mutation({
      query: (data) => ({
        url: `${oms_url}api/userorders/V2/createUserCartOrder`,
        method: "POST",
        headers: {
          "x-platform": "web"
        },
        body: data
      })
    }),
    deleteFromCart: build.mutation({
      query: (cartId) => ({
        url: `${oms_url}api/v2/cart/deleteCartItem/${cartId}`,
        method: "DELETE",
        headers: {
          "x-platform": "web"
        }
      })
    }),
    getMySubscriptions: build.mutation({
      query: (data) => ({
        url: `${oms_url}api/userorders/v2/mySubscription?grade=${data.grade}&userId=${data.userid}`,
        method: "GET",
        headers: {
          "x-platform": "web"
        }
      })
    }),
    paymentABortedbyUser: build.mutation({
      query: (data) => ({
        url: `${oms_url}api/payments/v2/cartPaymentAborted`,
        method: "POST",
        body: data
      })
    }),
    userSuccesPayment: build.mutation({
      query: (data) => ({
        url: `${oms_url}api/payments/v2`,
        method: "POST",
        body: data
      })
    })
  })
});

export const {
  useGetOmsPackagesMutation,
  useGetMySubscriptionsMutation,
  useSaveCartItemMutation,
  useGetAllCartItemsMutation,
  useCalculateDiscountMutation,
  useCreateUserCartOrderMutation,
  useDeleteFromCartMutation,
  usePaymentABortedbyUserMutation,
  useUserSuccesPaymentMutation
} = omsMutation;
