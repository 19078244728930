import React from "react";

export const CopySvg = () => {
  return (
    <svg
      className="copy-btn_svg"
      width="9"
      height="11"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.20005 2.29844C2.20005 1.92713 2.34755 1.57104 2.6101 1.30849C2.87265 1.04594 3.22875 0.898438 3.60005 0.898438H6.11025C6.38863 0.898683 6.65552 1.00947 6.85225 1.20644L9.59275 3.94624C9.78946 4.14308 9.89999 4.40995 9.90005 4.68824V9.29844C9.90005 9.66974 9.75255 10.0258 9.49 10.2884C9.22745 10.5509 8.87135 10.6984 8.50005 10.6984H3.60005C3.22875 10.6984 2.87265 10.5509 2.6101 10.2884C2.34755 10.0258 2.20005 9.66974 2.20005 9.29844V2.29844ZM3.60005 1.59844C3.4144 1.59844 3.23635 1.67219 3.10507 1.80346C2.9738 1.93474 2.90005 2.11279 2.90005 2.29844V9.29844C2.90005 9.48409 2.9738 9.66214 3.10507 9.79341C3.23635 9.92469 3.4144 9.99844 3.60005 9.99844H8.50005C8.6857 9.99844 8.86375 9.92469 8.99502 9.79341C9.1263 9.66214 9.20005 9.48409 9.20005 9.29844V5.09844H6.75005C6.47157 5.09844 6.2045 4.98781 6.00759 4.7909C5.81067 4.59399 5.70005 4.32692 5.70005 4.04844V1.59844H3.60005ZM6.40005 1.74334V4.04844C6.40005 4.14126 6.43692 4.23029 6.50256 4.29593C6.5682 4.36156 6.65722 4.39844 6.75005 4.39844H9.05515L6.40005 1.74334ZM0.800049 2.99844C0.800049 2.81279 0.873799 2.63474 1.00507 2.50346C1.13635 2.37219 1.3144 2.29844 1.50005 2.29844V9.29844C1.50005 9.85539 1.7213 10.3895 2.11512 10.7834C2.50895 11.1772 3.04309 11.3984 3.60005 11.3984H8.50005C8.50005 11.5841 8.4263 11.7621 8.29502 11.8934C8.16375 12.0247 7.9857 12.0984 7.80005 12.0984H3.55805C2.82658 12.0984 2.12507 11.8079 1.60785 11.2906C1.09062 10.7734 0.800049 10.0719 0.800049 9.34044V2.99844Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};
