import React, { useState } from "react";

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Who can apply for this program?",
      answer: "Anyone who meets the eligibility criteria can apply."
    },
    {
      question: "Will I be able to earn money while working in this program?",
      answer: "Yes, you will be compensated based on your performance."
    },
    {
      question: "What are the skills that students acquire when they sign up for Speech & Debate Events?",
      answer: (
        <ul>
          <li>
            <strong>Skill Building:</strong> Speech & Debating activities help to build Critical Thinking,
            Communication, Reasoning, Research Skills, and Confidence.
          </li>
          <li>
            <strong>Profile Building:</strong> High Schoolers (Grades 9 to 12th) can use their Speaking and Debate
            Achievements as part of their Extracurricular activities in foreign universities. Delhi University gives
            credits to Debate as part of University Admissions.
          </li>
        </ul>
      )
    },
    {
      question: "What is the Indian Debating League?",
      answer: (
        <ul>
          <li>Runs the largest public speaking and debating tournaments with over 80,300 students participating.</li>
          <li>Recognized by GESF Dubai and Singapore EdTech for innovative pedagogy.</li>
          <li>Recent wins at Princeton, LSE, Oxford Union, Stanford Juniors, and Kings College Invitationals.</li>
          <li>
            <a href="https://example.com" target="_blank" rel="noreferrer">
              Watch the video here
            </a>
          </li>
        </ul>
      )
    },
    {
      question: "Benefits for Students",
      answer: (
        <ul>
          <li>3 chances for students to qualify for finals.</li>
          <li>Compact 3-day MasterClasses with mock debates and real-time feedback.</li>
          <li>Social media recognition and certificates for all participants.</li>
          <li>Awards: Medals and in-kind awards for winners across various categories.</li>
        </ul>
      )
    },
    {
      question: "Benefits for Schools",
      answer: (
        <ul>
          <li>Recognition at the PAN India level for debaters and future-focused educators.</li>
          <li>Opportunities for profile building for students.</li>
          <li>Progressive school and educator awards for large delegations and best quality performances.</li>
        </ul>
      )
    },
    {
      question: "Eligibility",
      answer: (
        <ul>
          <li>
            <strong>Juniors:</strong> Grades 3rd to 5th
          </li>
          <li>
            <strong>Middle:</strong> Grades 6th to 8th
          </li>
          <li>
            <strong>Seniors:</strong> Grades 9th to 12th
          </li>
        </ul>
      )
    },
    {
      question: "Formats",
      answer: (
        <ul>
          <li>
            <strong>Speech: Original Oratory (OO):</strong> Individual event where participants write and deliver
            persuasive speeches.
          </li>
          <li>
            <strong>Speech: Dramatic Adaptation:</strong> Individual event focusing on acting skills and portraying
            assigned characters.
          </li>
          <li>
            <strong>Debate: World Schools Debate Championship (WSDC):</strong> Team event emphasizing teamwork,
            reasoning, and rebuttal skills.
          </li>
        </ul>
      )
    },
    {
      question: "How is IIT Madras involved?",
      answer: "The Debating Society of IIT Madras provides adjudication support and hosts the event."
    },
    {
      question: "What is the Registration Fee to participate in the event? Can we get a discount?",
      answer: (
        <ul>
          <li>The registration fee per student per event is Rs. 1,350/-.</li>
          <li>Students can participate in multiple formats by registering separately for each.</li>
          <li>Due to the high-quality event standards, discounts are not available.</li>
        </ul>
      )
    }
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className={`faq-item ${activeIndex === index ? "active" : ""}`}>
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {index + 1}. {faq.question}
              <span className="faq-icon">{activeIndex === index ? "▲" : "▼"}</span>
            </div>
            {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
      <div className="faq-footer">
        Still left with unanswered questions? Reach out to us at{" "}
        <a href="mailto:campusambassador@infinitylearn.com">campusambassador@infinitylearn.com</a>
      </div>
    </div>
  );
};

export default FAQSection;
