import React, { useMemo } from "react";
import Select from "react-dropdown-select";

const defCSC = {
  value: "Select...",
  isoCode: ""
};

export const DropdownSelect = ({ form, name, data, onSelect, disabled }) => {
  const {
    setValue,
    watch,
    formState: { errors }
  } = form;

  const [state] = watch([name]);

  const fieldError = useMemo(() => errors[name]?.value, [errors, name]);

  const onChange = (values) => {
    if (values.length) {
      const { value, isoCode } = values[0];
      const params = { value };
      if (isoCode) {
        params.isoCode = isoCode;
      }
      setValue(name, params, { shouldValidate: true });
      if (onSelect) {
        onSelect(values[0]); // Call the onSelect function with the selected value
      }
    }
  };

  const parseValues = useMemo(() => {
    const values = state?.value ? state : defCSC;
    values.label = values.value;
    return [values];
  }, [state]);

  return (
    <Select
      values={parseValues}
      options={data}
      className={`dpdSelect ${fieldError ? "error" : ""}`}
      onChange={onChange}
      dropdownHandleRenderer={customDropdownHandleRenderer}
      dropdownRenderer={customDropdownRenderer}
      disabled={data.length <= 0 || disabled}
      onSelect={onSelect}
    />
  );
};
const customDropdownHandleRenderer = ({ state }) => {
  return (
    <div className={`dropdownIcon ${state?.dropdown ? "open" : ""}`}>
      <svg fill="currentColor" viewBox="0 0 40 40">
        <path d="M31 26.4q0 .3-.2.5l-1.1 1.2q-.3.2-.6.2t-.5-.2l-8.7-8.8-8.8 8.8q-.2.2-.5.2t-.5-.2l-1.2-1.2q-.2-.2-.2-.5t.2-.5l10.4-10.4q.3-.2.6-.2t.5.2l10.4 10.4q.2.2.2.5z"></path>
      </svg>
    </div>
  );
};

const customDropdownRenderer = ({ props, state, methods }) => {
  const regexp = new RegExp(state.search, "i");

  return (
    <div className="" style={{ padding: 0 }}>
      {props.options
        .filter((item) => regexp.test(item[props.searchBy] || item.value))
        .map((option) => (
          <div
            key={option.value}
            className={`option ${methods.isSelected(option) ? "selected" : ""}`}
            onClick={() => methods.addItem(option)}
          >
            {option.value}
          </div>
        ))}
    </div>
  );
};
