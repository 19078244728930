export const schoolTypes = [
  {
    value: "Private",
    label: "Private"
  },
  {
    value: "Public",
    label: "Public"
  }
];

export const regExpValues = new RegExp(schoolTypes.map((item) => item.value).join("|"));
