import React from "react";
import loaderGif from "../../assets/gifs/LoaderGif.gif";

export const JoinButton = ({ title = "JOIN", className = "", isButtonDisabled, loader }) => {
  // const {
  //   formState: { isValid }
  // } = form;

  return (
    <div className={className}>
      <button
        className="button--membersubmit w-100"
        type="submit"
        disabled={isButtonDisabled}
        // style={!isValid ? { opacity: 0.6, cursor: "default" } : {}}
      >
        {loader ? (
          <div className="loader-container">
            <img className="loader-gif" src={loaderGif} alt="Loading.." />
            <p className="loader-text">Loading</p>
          </div>
        ) : (
          title
        )}
      </button>
    </div>
  );
};
