import React, { useState, useEffect } from "react";
import { ROUTES } from "../../constants/routes";
import { CubesSection } from "../components/CubesSection";
import { ExperienceSection } from "../components/ExperienceSection";
import { MembershipBenefitsSection } from "../components/MembershipBenefitsSection";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SchoolSignUpForm } from "./../auth-widget/school-auth-widget/forms/SchoolSignUpForm";

export const SchoolSignUpWidget = ({ toggleTAndC, togglePAndP }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isHomePage = history.location.pathname === ROUTES.HOME;
  const content = t("home_page.p3_content");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width condition as needed
    };

    handleResize(); // Set initial width
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the route changes
  }, []);

  return (
    <>
      {" "}
      <div>
        <header className="frontheader__banner">
          <div className="white__layerbg"></div>
          <div className="banner__contentbox no_auth">
            <div className="container">
              <div className="row">
                <div className={`col-lg-${isHomePage ? "12" : "7"}`}>
                  <h1 className="banner__contentbox--title">
                    <span className="text--secondary--grad">{t("home_page.p1_heading")}</span>
                  </h1>
                  <p className="banner__contentbox--text mt-2 pt-1">{content}</p>
                </div>
                <div id="signup" className="col-md-6 col-lg-5 position-relative">
                  <SchoolSignUpForm toggleTAndC={toggleTAndC} togglePAndP={togglePAndP} />
                </div>
              </div>
            </div>
          </div>
        </header>
        {isMobile && <ExperienceSection />}
        <CubesSection />
        {!isMobile && <ExperienceSection />}
        <MembershipBenefitsSection />
      </div>
    </>
  );
};
