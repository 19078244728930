import React from "react";
import { useSelector } from "react-redux";
import { CodeIcon, EmailIcon, FnameIcon, PhoneIcon, StateIcon } from "../../assets/svg";
import { getUserInfo } from "../../store/auth/selectors";

export const PersonalDetails = () => {
  const userInfo = useSelector(getUserInfo);

  return (
    <div
      className="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
      tabIndex="0"
    >
      <div className="row">
        <div className="col-12 col-md-6 mb-4">
          <div className="custom__personalbox__fieldbox">
            <label htmlFor="" className="fieldbox--label">
              First Name
            </label>
            <div className="position-relative">
              <FnameIcon className="personalbox--icon" />
              <input
                type="text"
                className="personalbox--inputfield w-100"
                defaultValue={userInfo.first_name || ""}
                disabled="true"
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <label htmlFor="" className="fieldbox--label">
            Last Name
          </label>
          <div className="position-relative">
            <FnameIcon className="personalbox--icon" />
            <input
              type="text"
              className="personalbox--inputfield w-100"
              defaultValue={userInfo.last_name || ""}
              disabled="true"
            />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <label htmlFor="" className="fieldbox--label">
            Primary Mobile Number
          </label>
          <div className="position-relative">
            <PhoneIcon className="personalbox--icon" style={{ top: 16 }} />
            <input
              type="text"
              className="personalbox--inputfield w-100"
              defaultValue={userInfo.phone || ""}
              disabled="true"
            />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <label htmlFor="" className="fieldbox--label">
            Email ID
          </label>
          <div className="position-relative">
            <EmailIcon className="personalbox--icon" style={{ top: 17 }} />
            <input
              type="text"
              className="personalbox--inputfield w-100"
              defaultValue={userInfo.email || ""}
              disabled="true"
            />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <label htmlFor="" className="fieldbox--label">
            State
          </label>
          <div className="position-relative">
            <StateIcon className="personalbox--icon" style={{ top: 11, left: 8 }} />
            <input type="text" className="personalbox--inputfield w-100" defaultValue={""} disabled="true" />
          </div>
        </div>
        <div className="col-12 col-md-12 mb-4 ord-1">
          <label htmlFor="" className="fieldbox--label">
            School Name
          </label>
          <div className="position-relative">
            <CodeIcon className="personalbox--icon" style={{ top: 15, left: 7 }} />
            <input
              type="text"
              className="personalbox--inputfield w-100"
              defaultValue={userInfo.school_id || ""}
              disabled="true"
            />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <label htmlFor="" className="fieldbox--label">
            School Code
          </label>
          <div className="position-relative">
            <CodeIcon className="personalbox--icon" style={{ top: 15, left: 7 }} />
            <input
              type="text"
              className="personalbox--inputfield w-100"
              defaultValue={userInfo.school_id || ""}
              disabled="true"
            />
          </div>
        </div>
        <div className="col-12 col-md-12 mb-4 ord-2">
          <label htmlFor="" className="fieldbox--label">
            School Name
          </label>
          <div className="position-relative">
            <CodeIcon className="personalbox--icon" style={{ top: 15, left: 7 }} />
            <input
              type="text"
              className="personalbox--inputfield w-100"
              defaultValue={userInfo.school_id || ""}
              disabled="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
