export const ROUTES = Object.freeze({
  HOME: "/home",
  PROFILE: "/profile",
  STUDENT: "/login",
  OTPLOGIN: "/login-OTP",
  SCHOOL_LOGIN: "/log-in",
  STUDENT_CREATE_ACCOUNT: "/student-create-account",
  SCHOOL_CREATE_ACCOUNT: "/school-create-account",
  // EXPLORE: "/explore",
  REQUEST_CODE: "/requestcode",
  PAGE_NOT_FOUND: "/page-not-found",
  FORGOT_PASSWORD: "/forgot-password",
  // FORGOT_PASSWORD_VERIFY_OTP: "/forgot-password/verify-otp",
  // FORGOT_PASSWORD_RESET_PASSWORD: "/forgot-password/reset-password",
  TANDC: "/terms-and-conditions",
  PANDP: "/privacy-and-policy",
  SCHOOL_ONBOARDING: "/school/onboarding",
  STUDENT_ONBOARDING: "/student/onboarding",
  DASHBOARD: "/dashboard",
  EXAMPAGE: "/exam-page",
  REPORTS: "/reports",
  IDL: "/idl"
});
