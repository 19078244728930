import React from "react";

import capture from "../../assets/images/frontside/capture1.png";
import Dot from "../../assets/svg/Dot.svg";
// import reeds from "../../assets/images/frontside/reeds-152740.mp4";
import reeds from "../../assets/images/frontside/Inmobius_Website_Intro_video.mp4";
import { useTranslation } from "react-i18next";

export const ExperienceSection = () => {
  const { t } = useTranslation();
  return (
    <section className="exp__exellence">
      <div className="container">
        <div className="row">
          <div className="col-md-6 order-md-1">
            <div className="video__content--desc">
              <h3 className="exp__exellence--heading text-md-start maxw--330 ms-md-start ">
                {t("home_page.p2_heading")}
              </h3>
              <p className="exp__exellence--text text-md-start mt-2 mt-md-3">{t("home_page.p2_content")}</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="video__bg" style={{ position: "relative" }}>
              <img src={Dot} alt="" className="img--dotted--left" />
              <video
                id="my-video"
                className="video-js"
                controls
                preload="auto"
                poster={capture}
                data-setup="{}"
                style={{ position: "relative", zIndex: "2", objectFit: "cover" }}
              >
                <source src={reeds} type="video/mp4" />
                <p className="vjs-no-js">
                  To view this video please enable JavaScript, and consider upgrading to a web browser that
                </p>
              </video>
              <img src={Dot} alt="" className="img--dotted--right" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
