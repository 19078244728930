import React, { useState, useRef, useEffect } from "react";
import Calling from "../../assets/svg/Calling.svg";

const ToolTip = ({ children, content, onClick }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleTooltipClick = (event) => {
    event.stopPropagation();
    setShowTooltip(true);

    // Invoke the onClick callback provided by the parent component
    if (onClick) {
      onClick(content);
    }
  };

  const handleMouseEnter = () => {
    if (!showTooltip) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="tooltip-container">
      <div
        className="tooltip-trigger"
        onClick={handleTooltipClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {showTooltip && (
        <div ref={tooltipRef} className="tooltip">
          <img className="call-icon" src={Calling} alt="Calling Icon" />
          {content}
          <div className="arrow-down"></div>
        </div>
      )}
    </div>
  );
};

export default ToolTip;
