import React from "react";

export const Tick = () => {
  return (
    <svg
      className="copy-btn_svg"
      width="14"
      height="14"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.57085 10.999L2.24585 7.67396L3.0771 6.84271L5.57085 9.33646L10.9229 3.98438L11.7542 4.81563L5.57085 10.999Z"
        fill="#1CC61C"
      />
    </svg>
  );
};
