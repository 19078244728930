import React from "react";
import green from "../../../assets/svg/emsat/greendot.svg";
import red from "../../../assets/svg/emsat/reddot.svg";
import gray from "../../../assets/svg/emsat/graydot.svg";
import line from "../../../assets/svg/emsat/Line 101.svg";

const TimeProgressors = ({
  totalTimeSpend,
  correctAnswerstimeSpend,
  incorrectAnswerstimeSpend,
  unansweredtimeSend,
  totaltime
}) => {
  const secondsToMinutes = (seconds) => (seconds / 60).toFixed(2);

  const secondsandMnt = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return `${minutes}m ${formattedSeconds}s`;
  };
  const getWidth = () => {
    if (totalTimeSpend <= 0 || totaltime <= 0) return "10%";
    return `${(secondsToMinutes(totalTimeSpend) / totaltime) * 100}%`;
  };

  const getCorrectTime = () => {
    if (totalTimeSpend <= 0 || correctAnswerstimeSpend <= 0) return "0%";
    return `${(secondsToMinutes(correctAnswerstimeSpend) / totaltime) * 100}%`;
  };

  const getIncorrectTime = () => {
    if (totalTimeSpend <= 0 || incorrectAnswerstimeSpend <= 0) return "0%";
    return `${(secondsToMinutes(incorrectAnswerstimeSpend) / totaltime) * 100}%`;
  };

  const getUnansweredTime = () => {
    if (totalTimeSpend <= 0 || unansweredtimeSend <= 0) return "0%";
    return `${(secondsToMinutes(unansweredtimeSend) / totaltime) * 100}%`;
  };

  const getIncorrectTimeMarginLeft = () => {
    return getCorrectTime();
  };

  const getUnansweredTimeMarginLeft = () => {
    return `${parseFloat(getCorrectTime()) + parseFloat(getIncorrectTime())}%`;
  };

  const getBackgroundColor = () => {
    if (totalTimeSpend <= 0) return "#D9DBE9";
    return "#fbd323"; // Default progress bar color
  };

  return (
    <>
      {" "}
      <div className="EMRep_timeProgress_card">
        <div className="EMRep-column-progress-textCont EMRep-column-timeprogress-textCont">
          <div className="EMRep_progres_container">
            <h1>Total Time Taken:</h1>
            <p>{secondsandMnt(totalTimeSpend)}</p>
          </div>
          <div className="EMRep_progres_container">
            <div className="EMRep_progressbar">
              <div
                className="EMRepprogress_filler"
                style={{
                  width: getWidth(),
                  backgroundColor: getBackgroundColor()
                }}
              >
                <div className="EMRepprogress_INfiller">
                  <img src={line} />
                  <img src={line} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="EMRep-column-2Cont">
          <div className="EMRep-timeprogress-dotcont">
            <div className="EMRep_question_container">
              <p className="EMRep-pointer_cont">
                <span>
                  <img src={green} alt="green dot" />
                </span>
                Correct Qs:
                <span className="EMRep-pointer_Qus">{secondsandMnt(correctAnswerstimeSpend)}</span>
              </p>
            </div>
            <div className="EMRep_progres_container">
              <div className="EMRep_progress_filler EMRep_progress_fillerCorret" style={{ width: getCorrectTime() }} />
            </div>
          </div>
          <div className="EMRep-timeprogress-dotcont">
            <div className="EMRep_question_container">
              <p className="EMRep-pointer_cont">
                <span>
                  <img src={red} alt="red dot" />
                </span>
                Incorrect Qs:
                <span className="EMRep-pointer_Qus">{secondsandMnt(incorrectAnswerstimeSpend)}</span>
              </p>
            </div>
            <div className="EMRep_progres_container">
              <div
                className="EMRep_progress_filler EMRep_progress_filleriInCorret"
                style={{
                  width: getIncorrectTime(),
                  marginLeft: getIncorrectTimeMarginLeft()
                }}
              />
            </div>
          </div>
          <div className="EMRep-timeprogress-dotcont">
            <div className="EMRep_question_container">
              <p className="EMRep-pointer_cont">
                <span>
                  <img src={gray} alt="gray dot" />
                </span>
                Unattempted Qs:
                <span className="EMRep-pointer_Qus">{secondsandMnt(unansweredtimeSend)}</span>
              </p>
            </div>
            <div className="EMRep_progres_container">
              <div
                className="EMRep_progress_filler EMRep_progress_fillerUnansr"
                style={{
                  width: getUnansweredTime(),
                  marginLeft: getUnansweredTimeMarginLeft()
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Mobile view */}
      <div className="EMRep_timeProgress_cardMob">
        <div className="EMRep_progres_container">
          <h1>Total Time Taken:</h1>
          <p>{totalTimeSpend}m</p>
        </div>
        <div className="EMRep_progres_containermob">
          <div className="EMRep_progres_container">
            <div className="EMRep_progressbar">
              <div
                className="progress_filler"
                style={{
                  width: getWidth(),
                  backgroundColor: getBackgroundColor()
                }}
              >
                {" "}
                <div className="EMRepprogress_INfiller">
                  <img src={line} alt="line" />
                  <img src={line} alt="line" />
                </div>
              </div>
            </div>
          </div>
          <div className="EMRep_progres_container">
            <div className="EMRep_progress_filler EMRep_progress_fillerCorret" style={{ width: getCorrectTime() }} />
          </div>
          <div className="EMRep_progres_container">
            <div
              className="EMRep_progress_filler EMRep_progress_filleriInCorret"
              style={{
                width: getIncorrectTime(),
                marginLeft: getIncorrectTimeMarginLeft()
              }}
            />
          </div>

          <div className="EMRep_progres_container">
            <div
              className="EMRep_progress_filler EMRep_progress_fillerUnansr"
              style={{
                width: getUnansweredTime(),
                marginLeft: getUnansweredTimeMarginLeft()
              }}
            />
          </div>
        </div>
        <div className="EMRep_graph_PMobCont">
          <p>
            <span>
              <img src={green} alt="green dot" />
            </span>{" "}
            Correct Qs : {secondsandMnt(correctAnswerstimeSpend)}
          </p>
          <p>
            <span>
              <img src={red} alt="red dot" />
            </span>{" "}
            Inorrect Qs : {secondsandMnt(incorrectAnswerstimeSpend)}
          </p>
          <p>
            <span>
              <img src={gray} alt="gray dot" />
            </span>{" "}
            Unattempted Qs : {secondsandMnt(unansweredtimeSend)}
          </p>
        </div>
      </div>
    </>
  );
};

export default TimeProgressors;
