import React, { useEffect, useState } from "react";

export const QuestionsBlock = ({ curQuestion, animateRef, onPick }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShow(true);
    }, 4500);

    return () => clearTimeout(loaderTimeout);
  }, []);

  return (
    <div className="px-3" ref={animateRef}>
      {show && (
        <div className="row justify-content-md-center mt-4 mt-md-5 pt-md-5">
          {curQuestion.answers.map((answer) => (
            <div key={answer.code} className="col-sm-6 col-md-3 mb-4">
              <div
                className="onboarding__list__item"
                onClick={() => onPick(curQuestion.question.code, curQuestion.question.question_id, answer.code)}
              >
                <div className="order-md-1">
                  <span className="on_litem_name">{answer.text}</span>
                </div>
                <div className="text-end position-relative text-md-center">
                  <div className="layerblur--circle"></div>
                  {answer.icon}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
