import React, { useState, useEffect } from "react";
import examNavLogo from "../../assets/svg/exampage/examNavLogo.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ExamPageNav = () => {
  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) setIsMobile(true);
    };

    handleResize(); // Call it initially
    window.addEventListener("resize", handleResize); // Add event listener to handle resize

    // Remove event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);
  const handleLogoClick = () => {
    history.push("/dashboard");
  };
  return (
    <>
      {" "}
      {!isMobile && (
        <nav className="examNavbar">
          <img className="examNavLogo" onClick={handleLogoClick} src={examNavLogo} alt="logo" />
        </nav>
      )}
    </>
  );
};
