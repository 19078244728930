import React from "react";
import WelcomeTinyPic from "../../../../assets/images/onboarding/welcome--tinypic.png";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../../store/auth/selectors";

export const StudentOnboardingWelcome = ({ onFinish }) => {
  const user = useSelector(getUserInfo);
  return (
    <div className="student__inner__widget">
      <img src={WelcomeTinyPic} alt="welcome--tinypic" className="d-block mx-auto img-fluid" />
      <h1 className="onboarding--heading text-center mt-4 pt-2">
        Welcome {`${user?.first_name} ${user?.last_name || ""}`}!
      </h1>
      <p className="onboarding--title text-center mt-3">
        We are excited to have you in this premium club of India. We would like to know you more for better
        personalisation of content for you.
      </p>
      <div className="text-center mt-4 pt-4 pt-md-2">
        <a href="#" onClick={onFinish} className="onboarding-grad--btn max--330">
          Continue
        </a>
      </div>
    </div>
  );
};
