import React, { useState, useEffect } from "react";
import { ROUTES } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TAndC } from "../T&C";
import { PAndP } from "../PP";
import { SchoolRequestCode } from "../auth-widget/school-auth-widget/forms/SchoolRequestCode";

export const RequestCodeWidget = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isHomePage = history.location.pathname === ROUTES.HOME;
  const content = t("home_page.p3_content");
  const [showTAndC, setShowTAndC] = useState(false);
  const [showPAndP, setShowPAndP] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the route changes
  }, []);
  const handleCloseModal = () => {
    setShowTAndC(false);
    setShowPAndP(false);
  };
  return (
    <>
      {" "}
      <div>
        {showTAndC && <TAndC handleClose={handleCloseModal} />}
        {showPAndP && <PAndP handleClose={handleCloseModal} />}
        <header className="frontheader__banner">
          <div className="white__layerbg"></div>
          <div className="banner__contentbox no_auth">
            <div className="container">
              <div className="row">
                <div className={`col-lg-${isHomePage ? "12" : "7"}`}>
                  <h1 className="banner__contentbox--title">
                    <span className="text--secondary--grad">{t("home_page.p1_heading")}</span>
                  </h1>
                  <p className="banner__contentbox--text mt-2 pt-1">{content}</p>
                </div>
                <div id="signup" className="col-md-6 col-lg-5 position-relative">
                  <SchoolRequestCode
                    toggleTAndC={() => setShowTAndC(!showTAndC)}
                    togglePAndP={() => setShowPAndP(!showPAndP)}
                  />
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};
