import React, { useEffect } from "react";
import WelcomeTinyPic from "../../../../assets/images/onboarding/welcome--tinypic.png";

export const StudentOnboardingFinish = ({ onFinish }) => {
  useEffect(() => {
    setTimeout(() => {
      onFinish();
    }, 1000);
  }, []);
  return (
    <div className="student__inner__widget">
      <img src={WelcomeTinyPic} alt="welcome--tinypic" className="d-block mx-auto img-fluid" />
      <h1 className="onboarding--heading text-center mt-4 pt-2">Hold Tight!</h1>
      <p className="onboarding--title text-center mt-1">We are Personalising your content</p>
    </div>
  );
};
