import React from "react";
import group from "../../assets/svg/exampage/group.svg";
import live from "../../assets/svg/exampage/live.gif";
import englishImage from "../../assets/svg/exampage/cardIllusions/english_dotoillution.svg";
import scoreImage from "../../assets/svg/exampage/cardIllusions/score_dotoillution.svg";
import mathImage from "../../assets/svg/exampage/cardIllusions/math_dotoillution.svg";
import witImage from "../../assets/svg/exampage/cardIllusions/wit_dotoillution.svg";
import hotsImage from "../../assets/svg/exampage/cardIllusions/hots_dotoillution.svg";
import { useSelector } from "react-redux";
import { getSelectedEventsDetails, getToken, getUserInfo } from "../../store/auth/selectors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CONFIG_VARS } from "../../constants/configuration";
export const ExamPageAtempt = ({ status }) => {
  const examdetailes = useSelector(getSelectedEventsDetails);
  const user = useSelector(getUserInfo);
  const history = useHistory();
  const handleViewReport = () => {
    history.push(`/reports/${examdetailes.crn_id}/${examdetailes.exam_id}`);
  };
  const access_token = useSelector(getToken);

  const currentExam = examdetailes.title.toLowerCase();
  const redirectToEvent = async () => {
    const baseUrl = `${CONFIG_VARS.scoreViewreportUrl}/test/testinstructions?test_type=e34d1d14-6c33-48fd-900e-e8d3cb76da64`;
    const examId = examdetailes.exam_id;
    const eventId = examdetailes.event_id;
    const token = access_token.replace("Bearer ", "");
    const url = `${baseUrl}&exam_id=${examId}&event_id=${eventId}&token=${token}`;
    window.open(url, "_self", "noreferrer");
  };
  const handleViewOldReport = () => {
    const baseUrl = `${CONFIG_VARS.scoreViewreportUrl}/inmobious`;
    const crn_id = user.crn_id;

    const token = access_token.replace("Bearer ", "");
    const url = `${baseUrl}?token=${token}&crn_id=${crn_id}
  `;
    //console.log(url);
    window.open(url, "_self", "noreferrer");
  };
  const getImage = (currentExam) => {
    switch (currentExam) {
      case "english":
        return englishImage;
      case "score":
        return scoreImage;
      case "math olympiad":
        return mathImage;
      case "wit":
        return witImage;
      case "hots olympiad":
        return hotsImage;
      case "i-emsat":
        return englishImage;
      default:
        return null;
    }
  };
  const getClass = (currentExam) => {
    switch (currentExam) {
      case "english":
        return "topcard-english";
      case "score":
        return "topcard-score";
      case "math olympiad":
        return "topcard-math";
      case "wit":
        return "topcard-wit";
      case "hots olympiad":
        return "topcard-hots";
      case "emsat":
        return "topcard-english";
      default:
        return null;
    }
  };
  return (
    <div className="AttemptCard">
      {currentExam && <img className="doto-illution" src={getImage(currentExam)} alt={currentExam} />}
      <div className={`top__card ${getClass(currentExam)}`}>
        <div className="exam-titleCont">
          <h3 className="examTitle">
            {examdetailes.title} {examdetailes.level} TEST
          </h3>
          <div className="examlivemob ">
            {!examdetailes?.exam_attempted && (
              <span className="staus-display">
                <img className="status-icon" src={live} alt="statud" />
                Live
              </span>
            )}
          </div>
        </div>
        <div className="group-cont">
          <img src={group} alt="group" />
          <p className="group-des">+90.7k attempted from your city.</p>
        </div>
        <div>{/* <p>60 questions 90 mits</p> */}</div>
      </div>
      <div className="bottam__card">
        <div className="examliveweb">
          {!examdetailes?.exam_attempted && (
            <span className="staus-display">
              <img className="status-icon" src={live} alt="statud" />
              Live
            </span>
          )}
        </div>
        {!examdetailes?.exam_attempted && status !== "Started" ? (
          <button onClick={redirectToEvent} className="attempt-btn">
            Attempt Now
          </button>
        ) : !examdetailes?.exam_attempted && status === "Started" ? (
          <button onClick={redirectToEvent} className="attempt-btn">
            Resume Test
          </button>
        ) : examdetailes?.exam_attempted && examdetailes?.event_provider !== "SCORE" ? (
          <button onClick={handleViewReport} className="attempt-btn">
            View Report
          </button>
        ) : examdetailes?.exam_attempted && examdetailes?.event_provider === "SCORE" ? (
          <button onClick={handleViewOldReport} className="attempt-btn">
            View Report
          </button>
        ) : null}
      </div>
    </div>
  );
};
