import React, { useState, useEffect, useMemo } from "react";
import { useTimer } from "react-timer-hook";
import { useForm } from "react-hook-form";
import { phoneNumberSchema } from "../../../constants/schemas";
import { useSendOTPMutation } from "../../../store/auth/otp.api";
import { PhoneInput } from "../../components/form/PhoneInput";
import { ROUTES } from "../../../constants/routes";
import OtpInput from "react-otp-input";
import { setPhoneNumberFormResolver } from "../../../constants/resolvers";
import { useVerifyOTPMutation } from "../../../store/auth/otp.api";
import { ResetPasswordForm } from "./forms/ResetPasswordForm";
import { useValidatePhoneMutation } from "../../../store/auth/auth.api";
import { useHistory } from "react-router-dom/";

export default function RecoverPassword({ onBackToLogin }) {
  const [sendOTP] = useSendOTPMutation();
  const [verifyOTP] = useVerifyOTPMutation();
  const [verifyPhoneNumber] = useValidatePhoneMutation();
  const form = useForm({ resolver: setPhoneNumberFormResolver });
  const { handleSubmit, setError, trigger, formState } = form;
  const { restart, seconds, isRunning } = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => {
      setTimerExpired(true);
      setIsTimerCompleted(true);
    }
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { isSubmitting } = formState;
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [timerExpired, setTimerExpired] = useState(false);
  const [isTimerCompleted, setIsTimerCompleted] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [otpValue, setOtpValue] = useState(Array(4).fill(""));
  const [step, setStep] = useState("setPhoneNumber");
  const history = useHistory();

  const handleCreateAcc = () => {
    history.push(ROUTES.STUDENT_CREATE_ACCOUNT);
  };
  const onBlur = async (fieldName) => {
    await trigger(fieldName);
  };

  const handlePhoneNumberChange = async (phoneNumber) => {
    if (phoneNumber.length === 10) {
      const result = await phoneNumber;
      if (result?.data?.isVerified) {
        setError("phoneNumber", { message: "Phone number verification failed" });
        setIsButtonDisabled(true); // Disable the button if phone number is invalid
      } else {
        clearErrors("phoneNumber");
        setValue("phoneNumberVerified", true);
        setIsButtonDisabled(false); // Enable the button if phone number is valid
      }
    } else {
      setError("phoneNumber", { message: "Phone number must be at least 10 digits" });
      setIsButtonDisabled(true); // Disable the button if phone number is invalid
    }
  };
  const handleOtpCodeChange = (e) => {
    if (e.length >= 0 && e.length <= 3) {
      // Handle case where OTP string is not 4 digits
      setError("otpCode", { type: "custom", message: "OTP must be exactly 4 digits." });
    } else {
      setError("otpCode", { type: "custom", message: "" });
    }
    const regex = /^[0-9]*$/; // Regular expression to allow only numerical characters
    if (!regex.test(e)) {
      // If input doesn't match the regex, remove the last entered character
      const otp = e.replace(/\D/g, ""); // Remove any non-numeric characters
      setOtpValue([...otp]);
    } else {
      setOtpValue([...e]);
    }
    setIsButtonDisabled(e.length !== 4);
  };

  const clickSendOTP = async () => {
    setOtpValue(Array(4).fill(""));
    setError("otpCode", { type: "custom", message: "" });
    setIsButtonDisabled(true);
    await trigger("phoneNumber");
    setIsTimerCompleted(false);

    const result = await verifyPhoneNumber(phoneNumber);
    console.log(result);
    if (!result?.data?.detail) {
      // Verify student login with new number (not registered number) case
      setShowOtpInput(false);
      setError("phoneNumber", { message: "Seems like you are a new user! Please create your account" });
      setIsButtonDisabled(true); // Disable the button if phone number not registered
    } else {
      setShowOtpInput(true);
    }

    if (phoneNumberIsValid) {
      const result = await sendOTP({ phoneNumber });

      if (result?.data) {
        const time = new Date();
        time.setSeconds(time.getSeconds() + 30);
        restart(time);
        setIsEditEnabled(true);
      }

      if (result?.error) {
        // Handle error
      }
    }
  };

  const clickVerifyOTP = async () => {
    // Get the OTP values from the input fields
    const otpString = otpValue.join("");
    setIsTimerCompleted(true);

    // Trigger validation for the otpCode field
    await trigger("otpCode");

    if (otpString.length === 4) {
      console.log("otpString:", otpString);
      setValue("otpCode", otpString);
      const result = await verifyOTP({ otpCode: otpString, phoneNumber });
      console.log(result, "result");
      if (result?.data && otpString && phoneNumber) {
        setStep("resetPassword");
      } else if (result?.error?.data?.message?.toLowerCase().includes("user does not exist")) {
        setError("phoneNumber", { type: "custom", message: "Mobile number is not registered. Please register." });
        setIsButtonDisabled(true);
      } else if (result?.error?.data?.message?.includes("Incorrect OTP")) {
        setError("otpCode", { type: "custom", message: "OTP is incorrect or not valid" });
        setIsButtonDisabled(true);
      } else {
        console.log(result);
        setError("otpCode", { type: "custom", message: "Oops! Something went wrong. Please try again.." });
        setIsButtonDisabled(true);
      }
    }
  };

  const handleEditNumberClick = () => {
    setIsEditEnabled(false);
    setShowOtpInput(false); // hiding OtpInput box
    setIsOtpSent(false); // disable button and setting back to the send otp state
    setValue("phoneNumber", ""); // Clear the phone number field
    setOtpValue(Array(4).fill("")); // Clear the OTP input field
    clearErrors("phoneNumber"); // Clear any errors related to the phone number field
    Array(4)
      .fill("")
      .forEach((_, index) => {
        clearErrors(`otpCode[${index}]`); // Clear any errors related to the OTP input field
      });
    restart();
    setIsTimerCompleted(true);
  };
  const handletoggle = () => {
    onBackToLogin();
  };

  const {
    formState: { errors },
    watch,
    setValue,
    clearErrors
  } = form;
  const [phoneNumber, ...otpCodes] = watch(["phoneNumber", ...Array(4).fill("otpCode")]);
  const phoneNumberIsValid = useMemo(() => phoneNumberSchema.isValidSync(phoneNumber), [phoneNumber]);

  const disabled = useMemo(() => {
    return {
      phoneForm: isRunning || isSubmitting,
      otpForm: !isRunning || isSubmitting
    };
  }, [isRunning, isSubmitting, phoneNumberIsValid]);

  useEffect(() => {
    if (timerExpired && isOtpSent) {
      setTimerExpired(false);
    }
    if (timerExpired && !isOtpSent) {
      setShowOtpInput(false); // Hide OTP input
      setTimerExpired(false);
    }
  }, [timerExpired, isOtpSent]);
  const handleButtonAction = async () => {
    if (!isButtonDisabled) {
      if (isOtpSent) {
        await clickVerifyOTP(); // Perform student login after OTP is sent successfully
      } else {
        await clickSendOTP(); // Send OTP
        setIsOtpSent(true);
        setIsTimerCompleted(false);
        // setShowOtpInput(true);
        setIsButtonDisabled(true);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(clickVerifyOTP)}>
      <div className="membership__card mx-auto ms-md-auto">
        {step === "setPhoneNumber" ? (
          <h3 className="tab-heading">Forgot Password</h3>
        ) : (
          <h3 className="tab-heading">Reset Password</h3>
        )}
        <div className="tab-content"></div>
        {step === "setPhoneNumber" ? (
          <>
            <div className="row">
              <div className="col-12 position-relative mb-12">
                <label className="tab-lable">Phone Number</label>
                <PhoneInput
                  form={form}
                  disabled={isEditEnabled}
                  handleChange={handlePhoneNumberChange}
                  onBlur={() => onBlur("phoneNumber")}
                  handleEditNumberClick={handleEditNumberClick}
                  isEditEnabled={isEditEnabled}
                />
                {errors.phoneNumber && <span className="error--strike--text">{errors.phoneNumber.message}</span>}
                {disabled.verifyButton &&
                  otpCodes.map((_, index) => {
                    return (
                      errors[`otpCode[${index}]`] && (
                        <span className="error--strike--text" key={index}>
                          {errors[`otpCode[${index}]`].message}
                        </span>
                      )
                    );
                  })}
              </div>
              {showOtpInput && (
                <div>
                  {!disabled.verifyButton && (
                    <div>
                      <label className="tab-lable">Enter OTP</label>
                      <div className="col-12 position-relative mb-12">
                        <div className="d-flex align-items-center">
                          {/* Replaced the individual input fields with OtpInput */}
                          <OtpInput
                            inputType="number"
                            skipDefaultStyles={false}
                            shouldAutoFocus="false"
                            value={otpValue.join("")}
                            onChange={(e) => handleOtpCodeChange(e)}
                            numInputs={4}
                            separator={<span> </span>}
                            isDisabled={disabled.otpForm}
                            renderInput={(props, index) => (
                              <input
                                {...props}
                                key={index}
                                type="text"
                                required
                                className={`otp-input-box ${
                                  errors.otpCode?.message ? "otp-error-strick" : "highlight"
                                }`}
                              />
                            )}
                          />
                          <div className="resend_otp">
                            {!isTimerCompleted && (
                              <span>
                                Resend OTP <br />({seconds} Sec.)
                              </span>
                            )}
                            {isTimerCompleted && (
                              <button className="resend-btn" onClick={clickSendOTP}>
                                Resend OTP
                              </button>
                            )}
                          </div>
                        </div>
                        {errors.otpCode && <span className="error--strike--text">{errors.otpCode.message}</span>}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <button className="button--membersubmit w-100" onClick={handleButtonAction} disabled={isButtonDisabled}>
              {isOtpSent ? "Continue" : "Send OTP"}
            </button>
          </>
        ) : step === "resetPassword" ? (
          <ResetPasswordForm phoneNumber={phoneNumber} />
        ) : null}
        <div className="hr-box">
          <hr className="hr-line" />
          <span>Or</span>
          <hr className="hr-line" />
        </div>
        <div className="col-12 mt-3 mb-3 pb-2 text-center">
          <a className="gard--textflow gard--textflow-mob" style={{ cursor: "pointer" }} onClick={handletoggle}>
            Back to Login
          </a>
        </div>
        <div className="col-12 create-ac-link">
          <p className="login--supporttext">
            Don&apos;t have an Inmöbius account?{" "}
            <span className="gard--textflow create-acoount" onClick={handleCreateAcc}>
              create account
            </span>
          </p>
        </div>
      </div>
    </form>
  );
}
