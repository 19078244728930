import React from "react";
import examNavLogo from "../../assets/svg/exampage/examNavLogo.svg";
import cell from "../../assets/svg/idl/cell.svg";
export const IDlNavBar = () => {
  return (
    <>
      <nav className="examNavbar">
        <img className="examNavLogo" src={examNavLogo} alt="logo" />
        <div className="helpSection">
          <div>
            <img className="cell" src={cell} alt="cell" />
          </div>
          <div className="helpcont">
            {" "}
            <span>Need Help? Talk to Experts</span>
            <a href="tel:1800419427" className="helpNumber">
              1800-419-427
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};
