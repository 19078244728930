import React from "react";
import Popup from "./PopUp";
import score from "../../../assets/doc/scoreReport.pdf";
import math from "../../../assets/doc/mathReport.pdf";
import hots from "../../../assets/doc/hotsReport.pdf";
import iemsat from "../../../assets/doc/iemsatReport.pdf";

const SampleReportPopup = ({ setIsPopupOpen, isPopupOpen, examType }) => {
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const getPdf = (examType) => {
    switch (examType) {
      case "score":
        return score;
      case "matholympiad":
        return math;
      case "hotsolympiad":
        return hots;
      case "iemsat":
        return iemsat;
      default:
        return null;
    }
  };
  const pdf = getPdf(examType);
  return (
    <div>
      <Popup show={isPopupOpen} examType={examType} onClose={closePopup} ispdf={true} pdf={pdf}></Popup>
    </div>
  );
};

export default SampleReportPopup;
