export const en = {
  translation: {
    home_page: {
      text: "HOME PAGE!",

      // PAGE 1
      p1_heading: "Welcome to InMöbius Club",
      p1_content:
        "Discover your academic strengths through tailored assessments and tests. Access resources aimed at improving your weak areas, guiding you towards academic excellence.",

      // PAGE 2
      p2_heading: "Experience the Excellence",
      p2_content:
        "With InMöbius, unlock a world of opportunities by elevating your school's results in academics and competitive exams, taking your school’s reputation to new heights.",

      // PAGE 3
      p3_heading: "Membership Benefits",
      p3_content:
        "A prestigious club designed to cater to the aspirations of school leaders. Gain exclusive access to assessments, tests, and content, valued up to Rs 5 lakh for free and elevate your school to unparalleled success.",

      p3_block1_header: "Students",
      p3_block1_item1: "Pinpoint learning gaps for focused improvement.",
      p3_block1_item2: "Exposure to diverse test patterns and performance ranking.",
      p3_block1_item3: "Ignite curiosity, critical thinking, problem solving and more.",
      // p3_block1_item4: "Olympiads",
      // p3_block1_item5: "Topper's Test Series",
      // p3_block1_item6: "Diagnostic Test",
      // p3_block1_item7: "Workshop and Webinars",

      p3_block2_header: "School",
      p3_block2_item1: "Access to a rich content library.",
      p3_block2_item2: "Grade, section, and year-on-year students' performance analysis.",
      p3_block2_item3: "Attract parents and students with InMöbius as an exclusive offering.",
      // p3_block2_item4: "Create Your Own Test",
      // p3_block2_item5: "Curriculum-based Masterclass",

      // PAGE 4
      p4_heading: "FAQs",

      p4_faq_item1: "How can I be a part of this club? Or How to get an invitation code?",
      p4_faq_content1_1: "Fill out the form by ",
      p4_faq_content1_2: " to help our team to reach you.",
      p4_faq_item2: "What are the eligibility criteria to become a member of Inmöbius?",
      p4_faq_content2: "Any school can apply to become an Inmobian.",
      p4_faq_item3: "What credit will my school get as a member?",
      p4_faq_content3:
        "The credit a school gets depends entirely on the number of students in school. 1. For a school with < 300students, the credit will be 3 lakhs. 2. For a school with >300 students, the credit will be 5 lakhs"
    },
    profile_page: "My Profile"
  }
};
