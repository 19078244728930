import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { SchoolOnboarding } from "./school/onboarding";

export const SchoolAuthPages = () => {
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function () {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  return (
    <section className="student__onboarding__widget w-100">
      <Switch>
        <Route exact path={ROUTES.SCHOOL_ONBOARDING} component={SchoolOnboarding} />
      </Switch>
    </section>
  );
};
