import React from "react";
import schoolsPic from "../../assets/images/frontside/schools--pic.svg";
import studentsPic from "../../assets/images/frontside/students--pic.svg";
import teachersPic from "../../assets/images/frontside/teachers--pic.svg";
import teachingPic from "../../assets/images/frontside/teaching--pic.svg";

export const CubesSection = () => {
  return (
    <section id="layercube__section" className="layercube__section">
      <div className="layered--gradbg"></div>
      <div className="container position-relative cubes z1">
        <div className="layercube__grid ">
          <div className="layercube__grid--item">
            <span>
              <img src={schoolsPic} alt="schools" />
            </span>
            <span className="text-center layercube__grid--text">
              <strong>500+</strong>
              <br />
              Schools
            </span>
          </div>
          <div className="layercube__grid--item">
            <span>
              <img src={studentsPic} alt="students" />
            </span>
            <span className="text-center layercube__grid--text">
              <strong>10000+</strong>
              <br />
              Students
            </span>
          </div>
          <div className="layercube__grid--item">
            <span>
              <img src={teachersPic} alt="teachers" />
            </span>
            <span className="text-center layercube__grid--text">
              <strong>500+</strong>
              <br />
              Teachers
            </span>
          </div>
          <div className="layercube__grid--item">
            <span>
              <img src={teachingPic} alt="teaching" />
            </span>
            <span className="text-center layercube__grid--text">
              <strong>25000+</strong>
              <br />
              Teaching Content
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
