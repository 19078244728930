import React, { useState, useEffect } from "react";
import RoadTrip from "../../../assets/svg/emsat/roadtrip.svg";
import you from "../../../assets/svg/emsat/you.svg";
import vertline from "../../../assets/svg/emsat/lineVert.svg";
import cesaer from "../../../assets/svg/emsat/ceser.svg";

// Component for the web version
const LineChartWeb = ({ totalScore, userScore, cutoff }) => {
  const [userPosition, setUserPosition] = useState(6.5);
  const [cutoffPosition, setCutoffPosition] = useState(6.5);

  const calculatePositionScore = (score, total) => {
    const minPosition = 6.5;
    const maxPosition = 38;
    const maxScore = total;

    if (score < 0) {
      return 3.5;
    }

    return minPosition + (maxPosition - minPosition) * (score / maxScore);
  };

  const calculatePositionCutoff = (score, total) => {
    const minPosition = 8;
    const maxPosition = 39.4;
    const maxScore = total;

    if (score < 0) {
      return 3.5;
    }

    return minPosition + (maxPosition - minPosition) * (score / maxScore);
  };

  useEffect(() => {
    const targetUserPosition = calculatePositionScore(userScore, totalScore);
    const targetCutoffPosition = calculatePositionCutoff(cutoff, totalScore);

    const timer = setTimeout(() => {
      setUserPosition(targetUserPosition);
      setCutoffPosition(targetCutoffPosition);
    }, 100);

    return () => clearTimeout(timer);
  }, [userScore, totalScore, cutoff]);

  return (
    <div className="EMRep_Sub_contIn">
      <div className="EMRep_Sub_TrackCont">
        <p className="EMRep_negtiveT">
          Negitive <br /> marks
        </p>
        <img className="EMRep_Sub_tripGraf" src={RoadTrip} alt="roadTrip" />
        <div className="EMRep_Sub_tripyou">
          <div style={{ left: `${userPosition}rem` }} className="EMRep_Sub_tripuyouImg animated-position">
            <p className="EMRep_score">{userScore}</p>
            <img className="EMRep_Sub_tripYou" src={you} alt="you" />
            <img className="EMRep_Sub_tripuyouline" src={vertline} alt="vertline" />
          </div>
          <div style={{ left: `${cutoffPosition}rem` }} className="EMRep_Sub_tripuyouImg animated-position">
            <div className=" EMRep_Sub_tripCeserCont">
              <p className="EMRep_cutoff">{cutoff}</p>
              <img className="EMRep_Sub_tripYou EMRep_Sub_tripCesr " src={cesaer} alt="cesaer" />
              <img className="EMRep_Sub_tripuCesline" src={vertline} alt="vertline" />
            </div>
          </div>
        </div>
        <div className="EMRep_Sub_tripnuber">
          <p className="EMRep_Sub_tripnuber0">0</p>
          <p className="EMRep_Sub_tripnuber300">{totalScore}</p>
        </div>
      </div>
      <div className="EMRep_Sub_ClgTirCont">
        <img src={cesaer} alt="cutoff" />
        <p> : Tier-1 College Cutoff</p>
      </div>
    </div>
  );
};

// Component for the mobile version
const LineChartMobile = ({ totalScore, userScore, cutoff }) => {
  const [userPosition, setUserPosition] = useState(3.5);
  const [cutoffPosition, setCutoffPosition] = useState(3.5);

  const calculatePositionScore = (score, total) => {
    const minPosition = 3.5;
    const maxPosition = 20;
    const maxScore = total;

    if (score < 0) {
      return 2;
    }

    return minPosition + (maxPosition - minPosition) * (score / maxScore);
  };

  const calculatePositionCutoff = (score, total) => {
    const minPosition = 4;
    const maxPosition = 21;
    const maxScore = total;

    if (score < 0) {
      return 2;
    }

    return minPosition + (maxPosition - minPosition) * (score / maxScore);
  };

  useEffect(() => {
    const targetUserPosition = calculatePositionScore(userScore, totalScore);
    const targetCutoffPosition = calculatePositionCutoff(cutoff, totalScore);

    const timer = setTimeout(() => {
      setUserPosition(targetUserPosition);
      setCutoffPosition(targetCutoffPosition);
    }, 100);

    return () => clearTimeout(timer);
  }, [userScore, totalScore, cutoff]);

  return (
    <div className="EMRep_Sub_contIn">
      <div className="EMRep_Sub_TrackCont">
        <p className="EMRep_negtiveT">
          Negitive <br /> marks
        </p>
        <img className="EMRep_Sub_tripGraf" src={RoadTrip} alt="roadTrip" />
        <div className="EMRep_Sub_tripyou">
          <div style={{ left: `${userPosition}rem` }} className="EMRep_Sub_tripuyouImg animated-position">
            <p className="EMRep_score">{userScore}</p>
            <img className="EMRep_Sub_tripYou" src={you} alt="you" />
            <img className="EMRep_Sub_tripuyouline" src={vertline} alt="vertline" />
          </div>
          <div style={{ left: `${cutoffPosition}rem` }} className="EMRep_Sub_tripuyouImg animated-position">
            <div className=" EMRep_Sub_tripCeserCont">
              <p className="EMRep_cutoff">{cutoff}</p>
              <img className="EMRep_Sub_tripYou EMRep_Sub_tripCesr " src={cesaer} alt="cesaer" />
              <img className="EMRep_Sub_tripuCesline" src={vertline} alt="vertline" />
            </div>
          </div>
        </div>
        <div className="EMRep_Sub_tripnuber">
          <p className="EMRep_Sub_tripnuber0">0</p>
          <p className="EMRep_Sub_tripnuber300">{totalScore}</p>
        </div>
      </div>
      <div className="EMRep_Sub_ClgTirCont">
        <img src={cesaer} alt="cutoff" />
        <p> : Tier-1 College Cutoff</p>
      </div>
    </div>
  );
};

// Main component that decides which version to render
export const LineChart = ({ totalScore, userScore, cutoff }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile ? (
    <LineChartMobile totalScore={totalScore} userScore={userScore} cutoff={cutoff} />
  ) : (
    <LineChartWeb totalScore={totalScore} userScore={userScore} cutoff={cutoff} />
  );
};
