import React from "react";
import Calling from "../../../assets/svg/Calling.svg";

const CallButton = () => {
  const phoneNumber = "7065937545"; // Replace with your pre-configured number

  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return <img src={Calling} onClick={handleCallClick} alt="call" />;
};

export default CallButton;
