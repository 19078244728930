import React, { useState, useEffect, useRef } from "react";
import "./TalkToExpertBanner.css";
import reportsPageBanner from "../../../assets/Banners/reportsPageTalkToExpertBanner.svg";
import reportsPageBannerMob from "../../../assets/Banners/reportsPageTalkToExpertBannerMob.svg";
import bannerArrow from "../../../assets/svg/talk_to_expert_banner_arrow.svg";

export const TalkToExpertBanner = ({ onBannerClick }) => {
  const [isVisible, setIsVisible] = useState(false);
  const bannerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (bannerRef.current && !bannerRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTriggerClick = () => {
    setIsVisible(true);
  };

  const handleBannerClick = (e) => {
    e.stopPropagation();
    setIsVisible(false);
    onBannerClick();
  };

  return (
    <>
      <div className={`banner-column ${isVisible ? "banner-visible" : ""}`} ref={bannerRef}>
        <picture>
          <source media="(max-width: 480px)" srcSet={reportsPageBannerMob} />
          <img
            src={reportsPageBanner}
            alt="Talk to Expert Banner"
            className="banner-image"
            onClick={handleBannerClick}
          />
        </picture>
      </div>

      <button className={`banner-trigger ${isVisible ? "trigger-hidden" : ""}`} onClick={handleTriggerClick}>
        <img src={bannerArrow} alt="Arrow" className="trigger-arrow" />
        <span className="banner-trigger-text">
          get free 1-1 counselling session with{" "}
          <span className="talk-to-expert-banner-highlight-text">our experts</span>
        </span>
      </button>
    </>
  );
};
