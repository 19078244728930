import React from "react";

const ProgressBar = ({ progress, total }) => {
  const getWidth = () => {
    if (progress < 0) return "10%";
    if (total === 0) return "0%";
    return `${(progress / total) * 100}%`;
  };
  const getBackgroundColor = () => {
    if (progress < 0) return "#D9DBE9";
    return "#fbd323"; // Default progress bar color
  };
  return (
    <div className="progressbar">
      <div className="progress_filler" style={{ width: `${getWidth()}`, backgroundColor: `${getBackgroundColor()}` }}>
        {" "}
        {progress < 0 && <p className="negitive_progress">{progress}</p>}
      </div>
    </div>
  );
};

export default ProgressBar;
