import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./locales/i18n";
import { PersistGate } from "redux-persist/integration/react";

import { MainRouter } from "./router/MainRouter";
import { persistor, store } from "./store";

import "./assets/css/bootstrap.min.css";
import "./assets/css/frontend.css";
import "./assets/css/onboarding.scss";
import "./assets/css/loader.css";
import "./assets/css/main.scss";
import "./assets/css/redesign.css";
import "./assets/css/Idl.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MainRouter />
    </PersistGate>
  </Provider>
);
